/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import { LoadingIndicator, Text } from '../components/flowComponents';
import { useGetSlackMessagesList } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { Link } from 'react-router-dom';

export const SlackMessagesList = () => {
  const { result: messages, fetching, error } = useAutomaticallyFetch(useGetSlackMessagesList, {}, { transform: result => result.messages })

  const loadingFinished = useFetchSucceeded(fetching, error)

  return (
    <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px;`}>
      {fetching && (
        <div css={css`display: flex; justify-content: center;`}>
          <Text>Loading usage data...</Text>
          <LoadingIndicator />
        </div>
      )}
      <Text></Text>
      {loadingFinished && <DataTable messages={messages} />}
    </div>
  )
}

const DataTable = ({ messages }) => {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>messageType</th>
          <th>teamName</th>
          <th>mainChannelName</th>
          <th>message sent/will be sent at</th>
          <th>text</th>
          {/* <th>modifiedAt</th> */}
          <th>sessionId</th>
        </tr>
      </thead>
      <tbody>
        {messages.map(message => {
          const { blocks, channel, id, mainChannelName, modifiedAt, sessionId, teamId, teamName, text, timestamp, messageType = "INITIAL_NOTIFICATION" } = message
          return (
            <tr key={id}>
              <td>{messageType}</td>
              <td>{teamName}</td>
              <td>{mainChannelName}</td>
              <td css={css`font-family: monospace;`}>{dayjs(timestamp * 1000).format('MM/DD/YYYY (ddd), hh:mm a z')}</td>
              <td>{text}</td>
              {/* <td css={css`font-family: monospace;`}>{dayjs(modifiedAt).format('MM/DD/YYYY (ddd), hh:mm a z')}</td> */}
              <td><Link to={`/session/${sessionId}/`}>{sessionId}</Link></td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}