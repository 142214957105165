import { toastError } from "./toaster";
import { HELP_EMAIL } from '../../../functions/shared/constants';

// Update Credential Error Codes
// from https://firebase.google.com/docs/reference/js/v8/firebase.User#linkwithpopup
//      https://firebase.google.com/docs/reference/js/v8/firebase.User#linkwithcredential
export enum AuthenticationError {
  ERR_CODE_CREDENTIAL_ALREADY_IN_USE = 'auth/credential-already-in-use',
  ERR_CODE_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  ERR_CODE_INVALID_EMAIL = 'auth/invalid-email',
  ERR_CODE_PROVIDER_ALREADY_LINKED = 'auth/provider-already-linked',
  ERR_CODE_POPUP_BLOCKED = 'auth/popup-blocked',
  ERR_CODE_POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user',
  ERR_CODE_USER_MISMATCH = 'auth/user-mismatch',
  ERR_CODE_WEAK_PASSWORD = 'auth/weak-password',
  ERR_CODE_INVALID_PASSWORD = 'auth/wrong-password',
  ERR_CODE_UNSUPPORTED_METHOD = 'flowclub/auth/unsupported-method',
  ERR_CODE_BAD_STATE = 'flowclub/auth/bad-state', // should never happen
  ERR_CODE_USER_NOT_FOUND = 'auth/user-not-found',
  ERR_CODE_EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  ERR_CODE_INVALID_ACTION_CODE = 'auth/invalid-action-code',
  ERR_CODE_USER_DISABLED = 'auth/user-disabled',
  ERR_CODE_TOO_MANY_REQUESTS = "auth/too-many-requests",
  ERR_CODE_POPUP_CLOSED_BY_USER_NOTAUTHFORSOMEREASON = 'popup_closed_by_user' // returned by gcal oauth flow
}

export function translateAuthErrorCodeToMessage(errorCode) {
  switch (errorCode) {
  case AuthenticationError.ERR_CODE_CREDENTIAL_ALREADY_IN_USE:
  case AuthenticationError.ERR_CODE_EMAIL_ALREADY_IN_USE:
    return "Oops! There is already an account with that email. Please choose a different email."
  case AuthenticationError.ERR_CODE_USER_NOT_FOUND:
    return "Oops! There is no account associated with this email. Please sign up instead."
  case AuthenticationError.ERR_CODE_INVALID_EMAIL:
    return "Oops! That does not seem to be a valid email. Please double check the spelling."
  case AuthenticationError.ERR_CODE_POPUP_BLOCKED:
    return "Oops! Looks like the login pop up got blocked. Make sure pop ups are allowed and then try again."
  case AuthenticationError.ERR_CODE_POPUP_CLOSED_BY_USER:
  case AuthenticationError.ERR_CODE_POPUP_CLOSED_BY_USER_NOTAUTHFORSOMEREASON:
    return "Oops! Looks like the pop up was closed before your Google account was selected. Please try again."
  case AuthenticationError.ERR_CODE_USER_MISMATCH:
    return `Oops! Looks like you chose the wrong account. Try again with the email currently attached to the account.`
  case AuthenticationError.ERR_CODE_WEAK_PASSWORD:
    return `Oops! That password is too weak. Please use a stronger password and try again.`
  case AuthenticationError.ERR_CODE_INVALID_PASSWORD:
    return `Incorrect password. Please try again.`
  case AuthenticationError.ERR_CODE_EXPIRED_ACTION_CODE:
    return "This reset password link has expired. Try resetting your password again and following the link you receive!"
  case AuthenticationError.ERR_CODE_INVALID_ACTION_CODE:
    return "This reset password link is invalid — it may have been copied incorrectly. Try resetting your password again and following the link you receive!"
  case AuthenticationError.ERR_CODE_USER_DISABLED:
    return `Your user account is currently disabled. Reach out to ${HELP_EMAIL} for support if you need to reactivate it`
  case AuthenticationError.ERR_CODE_TOO_MANY_REQUESTS:
    return "Access to your account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
  case AuthenticationError.ERR_CODE_PROVIDER_ALREADY_LINKED:
  case AuthenticationError.ERR_CODE_UNSUPPORTED_METHOD:
  case AuthenticationError.ERR_CODE_BAD_STATE:
  default:
    return `Oops! Something went wrong. Please try again or reach out to ${HELP_EMAIL}`
  }
}

export function translateAuthErrorToToast({ errorCode }) {
  toastError({ message: translateAuthErrorCodeToMessage(errorCode) })
}