/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext, useEffect, useState } from 'react';
import './Tray.css';
import './../Chat/Chat.scss';
import TrayButton, {
  TYPE_CAMERA_OFF,
  TYPE_CAMERA_ON,
  TYPE_LEAVE,
  TYPE_MORE,
} from '../TrayButton/TrayButton';

import { UserContext } from '../../../UserProvider';
import EventContext from '../../../EventContext';
import { MoreMenu } from '../more-menu/more-menu';
import { TYPE_OUTLINK, TYPE_PICTURE_IN_PICTURE } from '../Icon/Icon';
import { FC_DARK_BLUE, FC_RED, } from '../../../emotionVariables';
import { ExclusiveMouseoverWrapper } from '../../../Lounge/ExclusiveMouseoverWrapper';
import { Popover, usePopoverData } from '../../../components/Popover';
import { shift, offset } from '@floating-ui/react-dom';
import { InvitePopup } from './InvitePopup';
import { MicTrayButton } from './MicTrayButton';
import { CameraMenu } from './CameraMenu';
import { MicAndSpeakerMenu } from './MicAndSpeakerMenu';

export default function Tray() {
  const {
    pictureInPictureActive,
    togglePictureInPicture,
    toggleCamera,
    sessionWorkingTimeFinished,
    isCameraMuted,
    enableCallButtons,
    startLeavingCall,
    event,
    participants
  } = useContext(EventContext);

  const { user } = useContext(UserContext)
  const newUser = user.sessions === 0
  const [showMore, setShowMore] = useState(false)
  const [invitePopupActive, setInvitePopupActive] = useState(false)
  const [showCameraMenu, setShowCameraMenu] = useState(false)
  const [showMicAndSpeakerMenu, setShowMicAndSpeakerMenu] = useState(false)
  const isMac = navigator.platform.indexOf('Mac') > -1
  const { maxAttendees } = event

  const popoverPlacement = { placement: 'top', middleware: [shift({ padding: 0 }), offset(12)] }

  const moreMenuPopoverData = usePopoverData(popoverPlacement)
  const invitePopoverData = usePopoverData(popoverPlacement)
  const cameraMenuPopoverData = usePopoverData(popoverPlacement)
  const micAndSpeakerMenuPopoverData = usePopoverData(popoverPlacement)

  const toggleInvitePopup = () => setInvitePopupActive(invitePopupActive => !invitePopupActive)
  const toggleCameraMenu = () => setShowCameraMenu(showCameraMenu => !showCameraMenu)
  const toggleMicAndSpeakerMenu = () => setShowMicAndSpeakerMenu(showMicAndSpeakerMenu => !showMicAndSpeakerMenu)

  // This is a somewhat hand-wavey definition of "full", but we don't really have a good all-purpose definition due to reserved seats logic
  const eventIsFull = Object.keys(participants).length >= maxAttendees
  useEffect(() => { if (eventIsFull) { setInvitePopupActive(false) } }, [eventIsFull])

  return (
    <>
      {showCameraMenu &&
        <Popover floatingUIData={cameraMenuPopoverData} animate={false} closePopup={() => setShowCameraMenu(false)}>
          <CameraMenu closeFn={() => setShowCameraMenu(false)} />
        </Popover>
      }
      {showMicAndSpeakerMenu &&
        <Popover floatingUIData={micAndSpeakerMenuPopoverData} animate={false} closePopup={() => setShowMicAndSpeakerMenu(false)}>
          <MicAndSpeakerMenu closeFn={() => setShowMicAndSpeakerMenu(false)} />
        </Popover>
      }
      {invitePopupActive &&
        <Popover floatingUIData={invitePopoverData} animate={false} closePopup={() => setInvitePopupActive(false)}>
          <InvitePopup closeFn={() => setInvitePopupActive(false)} />
        </Popover>
      }
      {showMore &&
        <Popover floatingUIData={moreMenuPopoverData} animate={false} closePopup={() => setShowMore(false)}>
          <MoreMenu closeFn={() => setShowMore(false)} />
        </Popover>
      }
      <div css={css`text-align: center;`}>
        {newUser && sessionWorkingTimeFinished &&
          <div css={css`
            text-align: center;
            color: #000000;
            font-size: 14px;
            background: #fff;

            padding: 8px;
            border-radius: 4px;
          `}>
            Congrats on finishing your first session{user.displayName ? `, ${user.displayName}` : ''}!<br />
            After everyone's done sharing, click on <span css={css`color: ${FC_RED}; font-weight: bold;`}>red leave button</span> to leave feedback for your host!
          </div>
        }
        <div css={css`
          flex: none;
          height: 60px;
          padding: 0 10px;
          z-index: 3;

          display: inline-flex;
          align-items: flex-start;
          justify-content: center;
          gap: 4px;
        `}>
          <ExclusiveMouseoverWrapper>
            <div ref={cameraMenuPopoverData.reference}>
              <TrayButton
                type={isCameraMuted ? TYPE_CAMERA_OFF : TYPE_CAMERA_ON}
                disabled={!enableCallButtons}
                active={showCameraMenu}
                onClick={toggleCamera}
                tooltip={(isCameraMuted ? "Turn video on" : "Turn video off") + (isMac ? " (⌘+e)" : " (Ctrl+e)")}
                clickEvent={{ name: 'Camera', togglingTo: isCameraMuted }}
                fill={showCameraMenu ? '#ffffff' : isCameraMuted ? FC_RED : FC_DARK_BLUE}
                showSideButton
                onSideButtonClicked={toggleCameraMenu}
              />
            </div>
            <div ref={micAndSpeakerMenuPopoverData.reference}>
              <MicTrayButton
                isMac={isMac}
                active={showMicAndSpeakerMenu}
                toggleMicAndSpeakerMenu={toggleMicAndSpeakerMenu}
              />
            </div>
            <TrayButton
              type={TYPE_PICTURE_IN_PICTURE}
              disabled={!enableCallButtons || pictureInPictureActive}
              active={pictureInPictureActive}
              onClick={togglePictureInPicture}
              tooltip={"Picture in Picture"}
              fill={pictureInPictureActive ? '#ffffff' : FC_DARK_BLUE}
              clickEvent={{ name: 'Picture in Picture', togglingTo: !pictureInPictureActive }}
            />
            <div ref={invitePopoverData.reference}>
              <TrayButton
                type={TYPE_OUTLINK}
                disabled={!enableCallButtons || eventIsFull}
                active={invitePopupActive}
                onClick={toggleInvitePopup}
                tooltip={!eventIsFull ? "Invite to Session" : "This session is currently full, so invites are closed."}
                css={css`fill: ${FC_DARK_BLUE};`}
                clickEvent={{ name: 'Invite to Session', togglingTo: !invitePopupActive }}
              />
            </div>
            <div ref={moreMenuPopoverData.reference}>
              <TrayButton
                type={TYPE_MORE}
                disabled={!enableCallButtons}
                active={showMore}
                onClick={() => setShowMore(!showMore)}
                tooltip={"Settings..."}
                fill={showMore ? '#ffffff' : FC_DARK_BLUE}
                clickEvent={{ name: 'Settings', togglingTo: !showMore }}
              />
            </div>
            <TrayButton
              type={TYPE_LEAVE}
              disabled={!enableCallButtons}
              onClick={startLeavingCall}
              tooltip={"Leave & give feedback"}
              clickEvent={{ name: 'Leave', togglingTo: true }}
            />
          </ExclusiveMouseoverWrapper>
        </div>
      </div>
    </>
  );
}

