/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import { LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import { useGetBadgedUserStats } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { FC_BLUE } from '../emotionVariables';

export const UserBadgeStats = () => {
  const { result: badgedUserStats, fetching, error } = useAutomaticallyFetch(useGetBadgedUserStats, {}, { transform: result => result.badgedUserStats })

  const loadingFinished = useFetchSucceeded(fetching, error)

  return (
    <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px;`}>
      {fetching && (
        <div css={css`display: flex; justify-content: center;`}>
          <Text>Loading usage data...</Text>
          <LoadingIndicator />
        </div>
      )}
      <Text></Text>
      {loadingFinished && <DataTable badges={badgedUserStats} />}
    </div>
  )
}

const DataTable = ({ badges }) => {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th colSpan={2}></th>
          <th colSpan={4} style={{ "border-left": `2px solid ${FC_BLUE}`, "border-right": `2px solid ${FC_BLUE}`, "text-align": "center" }}>Averages Among Badged Users</th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th>Badged Users</th>
          <th style={{ "border-left": `2px solid ${FC_BLUE}` }}>Account Age</th>
          <th>{'% of Weeks Active (>1 session in week)'}</th>
          <th>Total Badges Chosen</th>
          <th style={{ "border-right": `2px solid ${FC_BLUE}` }}>Total Sessions in Period</th>
          <th>% of Badged Users Who Subscribe</th>
        </tr>
      </thead>
      <tbody>
        {badges.map(badge => {
          const { accountAgeMs, badgedUserCount, percentOfUsersSubscribed, percentOfWeeksActive, totalBadgesChosen, totalSessionsInPeriod } = badge.derivedData
          const { name, emoji } = badge.data
          const { id } = badge
          return (
            <tr key={id}>
              <td>{`${emoji} ${name}`}</td>
              <td>{badgedUserCount}</td>
              <td>{dayjs.duration(accountAgeMs).format(`Y ye[a]r[s], M [m]ont[hs], D [da]y[s], HH:mm:ss`)}</td>
              <td>{Math.floor(percentOfWeeksActive * 100)}%</td>
              <td>{totalBadgesChosen}</td>
              <td>{totalSessionsInPeriod}</td>
              <td>{Math.floor(percentOfUsersSubscribed * 100)}%</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}