/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { UserContext, usePreSignupUserDataProperty } from '../UserProvider';

import { GoalPane } from '../Onboarding/GoalPane';
import { SpecificGoalPane } from '../Onboarding/SpecificGoalPane';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { goalToMakeTimeFor, MAKING_TIME_FOR_GOALS } from '../../functions/shared/constants';
import { NowLaterPane } from '../Onboarding/NowLaterPane';
import { QuotesSidebar, QUOTE_TYPES } from '../Onboarding/QuotesSidebar';
import { FullScreenOnboarding } from '../Onboarding/FullScreenOnboarding';
import { GoalSidebar } from '../Onboarding/GoalSidebar';
import { CelebrationScreen } from '../IntentionOnboarding/CelebrationScreen';

const IntentionOnboardingWrapper = () =>
  <SegmentProvider eventLabel={'Intention'}>
    <IntentionOnboardingContent />
  </SegmentProvider>

const IntentionOnboardingContent = ({ }) => {
  const { user } = useContext(UserContext)
  const makingTimeFor = usePreSignupUserDataProperty('makingTimeFor')
  const makingTimeForText = goalToMakeTimeFor[makingTimeFor]
  const nowLaterTitle = `When would you like to focus on ${makingTimeForText || 'your goal'}?`
  const celebrationTitle = "Book another session"

  const celebrationScreen = {
    name: "CelebrationPane",
    MainContent: <NowLaterPane title={celebrationTitle} key="celebration" showUpcoming/>,
    sideDesktopContent: <CelebrationScreen />,
  }
  const stepScreens = [

    {
      name: "GoalPane",
      MainContent: <GoalPane key="goal" />,
      sideDesktopContent:
        makingTimeFor === undefined ? (
          <QuotesSidebar quoteType={QUOTE_TYPES.MEDIA} />
        ) : (
          <GoalSidebar selectedGoal={makingTimeFor} step="goal" />
        ),
    },
    {
      name: "SpecificGoalPane",
      MainContent: <SpecificGoalPane key="specific-goal" />,
      sideDesktopContent: (
        <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" />
      ),
      mobileContent: (
        <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" mobileView />
      )
    },
    {
      name: "ChooseTimePaneSet",
      name: "NowLaterPane",
      MainContent: <NowLaterPane title={nowLaterTitle} key="now-later" showUpcoming={false} />,
      sideDesktopContent: <QuotesSidebar quoteType={QUOTE_TYPES.USER} />,
    },
  ];

  return (
    <FullScreenOnboarding stepScreens={stepScreens} celebrationScreen={celebrationScreen} />
  )
}

export const IntentionOnboarding = IntentionOnboardingWrapper