/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { YetYeetAnotherBanner } from '../components/banners';
import { LightGrayText } from '../components/flowComponents';

export const EntryPage = ({ children, bannerContent, bottomContent, bannerStyle }) => {

  return (
    <>
      {bannerContent && <YetYeetAnotherBanner bannerStyle={bannerStyle}>{bannerContent}</YetYeetAnotherBanner>}
      <div css={css`
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 144px 42px 144px 42px;
      `}>
        <div css={css`
          width: 405px;
        `}>
          <h1 css={css`
            font-family: "Permanent Marker", "Sato", Helvetica, Arial, sans-serif!important;
            margin-bottom: 32px;
          `}>
            Flow Club
          </h1>
          <MainContentContainer>{children}</MainContentContainer>
          {bottomContent}
        </div>
      </div>
    </>
  )
}

const MainContentContainer = ({ children }) => {
  const mobileView = useMediaQuery({ query: '(max-width: 480px)' })

  const desktopStyle = css`
    box-shadow: 0px 1px 4px #D2DDEC;
    border-radius: 12px;
    padding: 42px;
  `

  return (
    <div css={!mobileView ? desktopStyle : ''}>
      {children}
    </div>
  )
}

export const LinkToLogin = () => {
  return (
    <LightGrayText>
      Already a member of Flow Club? <Link to="/login">Log in.</Link>
    </LightGrayText>
  )
}

export const LinkToSignUp = () => {
  return (
    <>
      <LightGrayText>
        Don't have an account?
      </LightGrayText>
      <LightGrayText>
        <Link to="/signup">Sign up for a free 7-day trial</Link>
      </LightGrayText>
    </>
  )
}


export const PasswordResetLink = ({ redirectAfterLogin }) => {
  return (
    <>
      <LightGrayText>
        Having trouble logging in?
      </LightGrayText>
      <LightGrayText>
        <Link to={`/reset-password${redirectAfterLogin ? `?redirect=${redirectAfterLogin}` : ''}`}>Get help logging in.</Link>
      </LightGrayText>
    </>
  )
}
