const {
  HostStatus,
  SubscriptionStatus,
  TRIAL_EXTENSION_SECOND_CHANGE_COOL_OFF,
  USER_SESSION_STATUS,
  GENERIC_HOST_IMAGE,
  ONE_DAY,
  CLUB_CLOSES_FOR_SIGNUPS_AFTER,
  CLUB_MAX_MEMBERS,
} = require("./constants");
const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc') // dependent on utc plugin
var timezone = require('dayjs/plugin/timezone')
const advancedFormat = require('dayjs/plugin/advancedFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

/* Note that we can't compile destructuring and other syntax here */


exports.getOrdinalNumber = function getOrdinalNumber(num) {
  if (num > 3 && num < 21) return `${num}th`;
  switch (num % 10) {
    case 1:  return `${num}st`;
    case 2:  return `${num}nd`;
    case 3:  return `${num}rd`;
    default: return `${num}th`;
  }
}

const isFunction = input => Object.prototype.toString.call(input) === "[object Function]"
exports.isFunction = isFunction

/**
 * Turns a list of objects:
 *   objects = [
 *     { id: A, data: { name: "Ked",     numSessions: 5 } },
 *     { id: B, data: { name: "Keddy",   numSessions: 8 } },
 *     { id: C, data: { name: "Kedster", numSessions: 8 } },
 *   ]
 * 
 *  Into a dict of lists by property:
 *   returned: {
 *     5: [
 *       { id: A, data: { name: "Ked",     numSessions: 5 } },
 *     ],
 *     8: [
 *       { id: B, data: { name: "Keddy",   numSessions: 8 } },
 *       { id: C, data: { name: "Kedster", numSessions: 8 } },
 *     ]
 *   }
 * 
 * 
 * @param objects - a list of objects
 * @param property - the property that we want to organize by
 * @returns 
 */
const groupObjectsByProperty = (objects, property) =>
  objects.reduce((allElements, element) => {
    const elementProperty = isFunction(property) ? property(element) : element[property]
    if (allElements[elementProperty] === undefined) { allElements[elementProperty] = [] }
    allElements[elementProperty].push(element)
    return allElements
  }, {})
exports.groupObjectsByProperty = groupObjectsByProperty;

// Converts an array of objects like [{id: 1, foo: 'bar'}, {id: 2, 'bar': 'baz'}, ...]
// to an object { '1': { id: 1, foo: 'bar' }, '2': { id: 2, bar: 'baz' } }
const arrObjectsToObject = (arrOfObjects, key = 'id') => {
  return arrOfObjects.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})
}
exports.arrObjectsToObject = arrObjectsToObject;

// I'm 200% sure lodash has some version of this but I'm an irredeemable hipster and don't like being dependent on lodash
const sortBy = (array, sortProperty, descending = false) => {
  const sortedArray = Array.from(array)

  const getProperty = (arrayElement) => {
    if (isFunction(sortProperty)) { return sortProperty(arrayElement) }
    if (sortProperty !== undefined) { return arrayElement[sortProperty] }
    return arrayElement
  }

  sortedArray.sort((a, b) => {
    const propertyA = getProperty(a)
    const propertyB = getProperty(b)
    const valueA = (propertyA !== undefined && propertyA !== null) ? propertyA : Infinity
    const valueB = (propertyB !== undefined && propertyB !== null) ? propertyB : Infinity
    const flipped = descending ? -1 : 1
    const relation = valueA > valueB ? 1 : valueB > valueA ? -1 : 0
    return relation * flipped
  })
  return sortedArray
}
exports.sortBy = sortBy;
// just a simpler interface for arrays of primitives
const sort = (array, descending) => sortBy(array, undefined, descending)
exports.sort = sort;

const shuffleArray = (array) => {
  const newArray = []
  const oldArray = Array.from(array)
  while (oldArray.length > 0) {
    const pickedElement = oldArray.splice(Math.floor(Math.random() * oldArray.length), 1)[0]
    newArray.push(pickedElement)
  }
  return newArray
}
exports.shuffleArray = shuffleArray

const getUserAverageFlowScore = user => user.scoredSessions && user.scoredSessions > 0 ? (user.totalFlowScore / user.scoredSessions).toFixed(1) : '-'
exports.getUserAverageFlowScore = getUserAverageFlowScore;

const getUserHoursOfFlow = user => user.minutes && user.minutes > 0 ? Math.round(user.minutes / 60) : '-'
exports.getUserHoursOfFlow = getUserHoursOfFlow;

// honboard = do host onboarding
// this name is more fun though
const getUserIsEligibleToHonboard = user => user !== null && [
  HostStatus.QUALIFIED,
  HostStatus.NOT_NOW
].includes(user.hostStatus)
exports.getUserIsEligibleToHonboard = getUserIsEligibleToHonboard;

const getUserIsHost = user => user !== null && [
  HostStatus.ACTIVE,
  HostStatus.DORMANT,
  HostStatus.INACTIVE
].includes(user.hostStatus)
exports.getUserIsHost = getUserIsHost;
const getUserHasAdminPermissions = user => (user !== null && user.isAdmin === true)
exports.getUserHasAdminPermissions = getUserHasAdminPermissions;
const getUserCanHostSessions = user => getUserIsHost(user) || getUserHasAdminPermissions(user)
exports.getUserCanHostSessions = getUserCanHostSessions;

exports.getUserIsUnauthed = user => [SubscriptionStatus.UNAUTHED_USER, SubscriptionStatus.EXTREMELY_UNAUTHED_USER].includes(user.subscriptionStatus)

exports.getUserHasSubscriptionAccess = user =>
  [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.ACTIVE_COMPLIMENTARY,
    SubscriptionStatus.ACTIVE_BUT_TRIALING,
    SubscriptionStatus.UNAUTHED_USER,
    SubscriptionStatus.EXTREMELY_UNAUTHED_USER,
  ].includes(user.subscriptionStatus) ||
  (user.subscriptionStatus === SubscriptionStatus.TRIALING_NO_CARD && user.trialEnd.toMillis() > Date.now())

exports.getSessionIsJoinable = (userSessionStatus) => [
  USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER
].includes(userSessionStatus)
exports.getSessionIsHappeningNow = (userSessionStatus) => [
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE,
].includes(userSessionStatus)
exports.shouldShowSessionOnSchedule = (userSessionStatus) => ![
  USER_SESSION_STATUS.NOT_JOINABLE_HAPPENING_NOW,
  USER_SESSION_STATUS.NOT_JOINABLE_NOT_FOR_FIRST_TIMERS,
  USER_SESSION_STATUS.NOT_JOINABLE_FULL,
].includes(userSessionStatus)
exports.getUserHasBookedSession = (userSessionStatus) => [
  USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.PARTICIPANT_PAST_SESSION,
  USER_SESSION_STATUS.HOST_FUTURE_SESSION,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.HOST_PAST_SESSION
].includes(userSessionStatus)


// Users are allow to extend their trial if they:
  // 1) Haven't already extended their trial
  // 2) Have either been to < 5 sessions OR their trial expired > 1 week ago (even if they went to 5+ sessions)
exports.userEligibleForTrialExtension = (user) => {
  // User's trial must have ended at least TRIAL_EXTENSION_SECOND_CHANGE_COOL_OFF days ago
  const  trialExtensionSecondChanceCutoffDate =  new Date(new Date().getTime() - TRIAL_EXTENSION_SECOND_CHANGE_COOL_OFF) // 7 
  return !user.trialExtensionSubmitted && (
    user.sessions < 5 || (
      user.trialEnd && user.trialEnd.toDate() <= trialExtensionSecondChanceCutoffDate))
}

const DAYS_AFTER_CANCELLING_BEFORE_PROMO_OFFER = 3 * ONE_DAY

exports.userEligibleForResubscribePromo = (user) => (
  user.canceledAt !== undefined && user.canceledAt.toDate() < new Date(new Date().getTime() - DAYS_AFTER_CANCELLING_BEFORE_PROMO_OFFER) && !user.hasUsedReflowPromo
)

const getEmptyHostId = (environment) =>
  environment === 'production' ? '9E0n40ccoLMgjxqpTw9Wk5hJZix1' :
    environment === 'test' ?
      '6231ad4990134ce48c922f9a030784f1' :
      'bC1vbEsE19VYHahPEneJIL4UIkq1'
exports.getEmptyHostId = getEmptyHostId

/**
 * Generates a slug for the given name
 * @param name the name to slugify
 */
const normalizeSlug = function (name) {
  return name.trim().replace(/\s+/g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
}
exports.normalizeSlug = normalizeSlug

const toTitleCase = (str) => {
  return str.toLowerCase().split(' ').map(
    word => word.replace( word[0], word[0].toUpperCase())
  ).join(' ')
}

exports.toTitleCase = toTitleCase


const arrToSentenceList = (arr) => {
  return arr.slice(0, -1).join(', ') + (arr.length > 1 ? ', and ' : '') + arr.slice(-1)
}

exports.arrToSentenceList = arrToSentenceList

const getImageForHost = (hostUser, imageProperty = "image") => hostUser[imageProperty] ?? GENERIC_HOST_IMAGE
exports.getImageForHost = getImageForHost

const getAgendaForEvent = (duration, pomodoroEnabled, breakCheckIns = false) => {
  const workDuration = pomodoroEnabled ?
    25 :
    (duration === 90 ? 80 : 50)
  const breakDuration = duration > 30 ? 5 : 2
  const initialBreakDuration = duration > 30 ? breakDuration : 3

  let agenda = [
    {
      activeWorkingTime: false,
      duration: initialBreakDuration,
      name: 'share goals'
    },
  ]
  let remainingDuration = duration - agenda[0].duration
  while (remainingDuration > 0) {
    if (remainingDuration > workDuration + breakDuration) {
      agenda.push(...[
        {
          activeWorkingTime: true,
          duration: workDuration,
          name: 'muted deep work'
        },
        {
          activeWorkingTime: false,
          duration: breakDuration,
          name: breakCheckIns ? 'break & check in' : 'break'
        }
      ])
      remainingDuration -= workDuration + breakDuration
    } else {
      const lastWorkSegmentDuration = remainingDuration - breakDuration
      if (lastWorkSegmentDuration < 10) {
        agenda[agenda.length - 2].duration += remainingDuration
        agenda[agenda.length - 1].name = 'celebrate'
      } else {
        agenda.push(...[
          {
            activeWorkingTime: true,
            duration: lastWorkSegmentDuration,
            name: 'muted deep work'
          },
          {
            activeWorkingTime: false,
            duration: breakDuration,
            name: 'celebrate'
          }
        ])
      }
      remainingDuration = 0
    }
  }

  return agenda
}
exports.getAgendaForEvent = getAgendaForEvent

const getPrivateSessionDescriptionForTag = tag => `${toTitleCase(tag)}-only`
exports.getPrivateSessionDescriptionForTag = getPrivateSessionDescriptionForTag

const getTitleForEvent = (duration, pomodoro, chatOnly, visibility, eventType) =>
  `${duration} min${chatOnly ? ' Chat Only' : ''}${pomodoro ? ' Pomodoro' : ''}${visibility !== 'public' && visibility !== 'private' ? ` ${getPrivateSessionDescriptionForTag(visibility)}` :''}${eventType.hideEventTypeInSessionTitle ? '' : ` ${eventType.title}`} Flow Club`
exports.getTitleForEvent = getTitleForEvent;


const truncateTextWithEllipses = (text, limit) => {
  if (text === null) { 
    return '';
  }
  if (text.length <= limit) {
    return text;
  }
  let words = text.split(' ');
  if (words.length === 0) {
    return text;
  }
  let truncated = '';
  let charCount = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (charCount + word.length + 1 > limit) {
      break
    };
    charCount += word.length + 1;
    truncated += word + ' ';
  }
  return truncated.trim() + '...';
};

exports.truncateTextWithEllipses = truncateTextWithEllipses;

/* Note that we can't compile destructuring and other syntax here */

/*
 * Clubs are open for signups iff:
 * - There are currently fewer than 10 members
 * - The club has a set start date, and that start date is no more than CLUB_CLOSES_FOR_SIGNUPS_AFTER days ago
 */

const getClubIsOpenForSignups = (club) => {
  const startDate = club.startDate
  const members = club.members
  if (!startDate || members >= CLUB_MAX_MEMBERS) {
    return false
  }
  const joinCutoffDate = new Date(new Date(startDate).getTime() + CLUB_CLOSES_FOR_SIGNUPS_AFTER)
  const now = Date.now()
  return joinCutoffDate > now
}
exports.getClubIsOpenForSignups = getClubIsOpenForSignups

/*
 * Compares clubs and sorts them for the directory, without considering start date, which needs to be compared after this sort.
 * Clubs that are open for signup should go first, then clubs that haven't started yet, then clubs that have started but not ended, then clubs that have ended.
 */
const getClubSortOrder = (club) => {
  const startDate = club.startDate
  const joinCutoffDate = new Date(new Date(startDate).getTime() + CLUB_CLOSES_FOR_SIGNUPS_AFTER)
  const now = Date.now()
  const isOpenForSignups = getClubIsOpenForSignups(club)
  if (isOpenForSignups) {
    return 1
  }
  if (!startDate || startDate > now) {
    return 2
  }
  if (now > joinCutoffDate) {
    return 3
  }
  return 4
}

exports.getClubSortOrder = getClubSortOrder
