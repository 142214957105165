/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from 'react-router-dom';
import { CalendarGoalEntry } from "../CalendarGoalEntry"
import { usePreferences } from '../../UserProvider'


export const HostGoalEntry = ({
  eventId,
  sessionStart,
  recurrences = 1,
  hostGoalDescription,
  hostGoalEmoji,
}) => {
  const { disableCalendarInvites } = usePreferences()

  const calendarInviteMessage = recurrences === 1 ? (
    `We'll send you a calendar invite for ${sessionStart.format('ddd, MMM D @ h:mmA z')}.`
  ) : `We'll send you a recurring calendar invite for ${recurrences} weeks on ${sessionStart.format('dddd[s] @ h:mmA z')}.`

  const hostGoalMessage = hostGoalDescription && hostGoalEmoji ? `${hostGoalEmoji} ${hostGoalDescription}` : ''

  return (
    <>
      <div css={css`
        padding: 24px 24px 48px 24px;
        background: #f6f6f6;
        border-radius: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        hyphens: auto;
        color: #000000;
        text-align: center;
        margin: 15px 0px;
      `}>
        {disableCalendarInvites ?
        <div>
          Calendar invites turned off in <Link to="/settings/account">settings</Link>.<br/>
         Access this session from the top of the schedule or from <Link to="/profile/sessions">My Sessions</Link>.
        </div>
        :
        <div>
        <div css={css`
          text-align: center;
          font-size: 20px;
          font-family: "Sato";
        `}>
          Add to Calendar
        </div>
        <div css={css`
          max-width: 460px;
          margin: 10px auto;
        `}>
          <p>
            {calendarInviteMessage}
          </p>
          {recurrences > 1 &&
            <p css={css`
              text-align: left;
              color: rgba(0, 0, 0, 0.6);
            `}>
              Feel free to add a goal for the first session if you know what you want to work on!
            </p>
          }
          <CalendarGoalEntry eventId={eventId} initialCalendarTitle={hostGoalMessage} />
        </div>
        </div>
        }
        <div css={css`clear: both;`}></div>
      </div>
    </>
  )
}
