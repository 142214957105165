/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { createContext, useContext, useState } from "react";
import { FC_DARK_BLUE, FC_LIGHTER_BLUE, TEXT_60_PERCENT } from '../../emotionVariables';
import { DarkText, DropdownToggleButton, LinkStyledText } from '../../components/flowComponents';
import Icon, { TYPE_CHECKMARK } from '../../daily/daily-components/Icon/Icon';
import { useClickOutside } from './useClickOutside';
import { Link } from 'react-router-dom';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FAVORITE_HOSTS_FILTER } from '../../../functions/shared/constants';

const HostFiltersContext = createContext(null)

export const HostFilters = ({ filterOptions, activeFilters }) =>
  <SegmentProvider eventLabel={'Host Filters'}>
    <HostFiltersContent filterOptions={filterOptions} activeFilters={activeFilters} />
  </SegmentProvider>

const HostFiltersContent = ({ filterOptions, activeFilters }) => {
  const activeFiltersList = [...filterOptions, FAVORITE_HOSTS_FILTER].filter(host => activeFilters[host])
  const activeFiltersCount = activeFiltersList.length
  const dropdownName = activeFiltersCount === 0 ?
    'Hosts' :
    activeFiltersCount === 1 ?
      activeFiltersList[0] :
      `${activeFiltersList[0]}+${activeFiltersCount - 1}`

  const sendSegmentEvent = useSendSegmentEvent()
  const dropdownOpened = () => {
    sendSegmentEvent('Opened')
  }
  const dropdownClosed = () => {
    sendSegmentEvent('Closed')
  }

  return (
    <HostFiltersContext.Provider value={{ filterOptions, activeFilters }}>
      <DropdownToggleButton
        label={dropdownName}
        onOpen={dropdownOpened}
        onClose={dropdownClosed}
        customCss={activeFiltersCount > 0 && css`
          background-color: ${FC_LIGHTER_BLUE};
          border-color: ${FC_DARK_BLUE};
        `}
        height={400}
      >
        <DropdownContent/>
      </DropdownToggleButton>
    </HostFiltersContext.Provider>
  )
}

const DropdownContent = ({ openButtonRef, closeDropdown }) => {
  const { filterOptions, activeFilters } = useContext(HostFiltersContext)
  const anyFiltersActive = Object.keys(activeFilters)[0] !== 'undefined'

  const sendSegmentEvent = useSendSegmentEvent()
  const clickedOutside = (_, eventType) => {
    const dropdownWasClosed = closeDropdown()
    if (dropdownWasClosed) {
      if (eventType === 'click') {
        sendSegmentEvent('Dropdown Clicked Outside')
      } else {
        sendSegmentEvent('Escape Key Pressed')
      }
    }
  }

  const clearAllClicked = () => {
    sendSegmentEvent('Clear All Clicked')
  }

  const doneClicked = () => {
    closeDropdown()
    sendSegmentEvent('Done Clicked')
  }

  const clickOutsideRef = useClickOutside({ onTriggered: clickedOutside, additionalIgnoredRefs: [openButtonRef] })
  const [searchTerm, setSearchTerm] = useState("");

  return <div ref={clickOutsideRef} css={css`
    width: 100%;
    height: 100%;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  `}>
    <div css={css`
      padding: 16px 16px 14px 16px;
      border-bottom: 1px solid #D9D9D9;
      flex-grow: 1;
      overflow: auto;
    `}>
      <div css={css`padding-bottom: 16px; border-bottom: 1px solid #D9D9D9;`}>
        <FilterOption
          name={FAVORITE_HOSTS_FILTER}
          selected={activeFilters[FAVORITE_HOSTS_FILTER]}
        />
      </div>

      <input 
      value={searchTerm} 
      onChange={(e) => setSearchTerm(e.target.value)} 
      placeholder="Search for a specific host..."
      css={css`
        width: 100%;
        padding: 8px;
        margin: 8px 0;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        margin: 16px 0;
      `}
    />
      {filterOptions
      .filter(host => host.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((host, index) => 
        <div key={`${host}${index}`} css={css`margin-top: 8px;`}>
          <FilterOption
            name={host}
            selected={activeFilters[host]}
          />
        </div>
      )}
    </div>
    <div css={css`display: flex; justify-content: flex-end; min-height: 46px; flex-grow: 0; align-items: center;`}>
      <Link to={`/upcoming`} onClick={clearAllClicked}>
        <LinkStyledText customCss={css`
          margin-right: 16px;
          ${!anyFiltersActive && css`
            cursor: initial;
            color: ${TEXT_60_PERCENT};
            user-select: none;
          `}
        `}>Reset</LinkStyledText>
      </Link>
      <LinkStyledText onClick={doneClicked} customCss={css`margin-right: 16px;`}>Done</LinkStyledText>
    </div>
  </div>
}

const FilterOption = ({ name, selected = false }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const filterToggled = () => {
    sendSegmentEvent('Filter Option Toggled', { name })
  }

  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  return (
    <Link to={`/upcoming${selected ? '' : `/${name}`}`} onClick={filterToggled}>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        css={css`display: flex; align-items: center; cursor: pointer;`}
      >
        <div css={css`
          width: 24px;
          height: 24px;
          border: 1px solid ${selected ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
          border-radius: 4px;
          ${selected && css`background-color: ${FC_LIGHTER_BLUE};`}

          transition: transform 0.2s;
          ${hovered && css`
            border-color: ${FC_DARK_BLUE};
            transform: scale(1.1);
          `}

          display: flex;
          justify-content: center;
          align-items: center;

          margin-right: 12px;

          ${selected && css`animation: ${selectedAnimation} 0.3s;`}
        `}>
          <Icon type={TYPE_CHECKMARK} css={css`fill: ${FC_DARK_BLUE}; width: 16px; height: 12px; opacity: ${selected ? '1' : '0'}; ${selected && css`transition: opacity 0.3s;`}`}/>
        </div>
        <DarkText>
          {name}
        </DarkText>
      </div>
    </Link>
  )
}