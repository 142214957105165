/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NEUTRAL_90, FC_GREEN, FC_RED, FC_LIGHTER_BLUE, } from '../../emotionVariables';
import { shouldShowClickAllow } from './Call/callState';
import { FlowButton, BUTTON_STYLES } from '../../components/flowComponents';
import Icon, { TYPE_CLOSE_UNSET_FILL } from './Icon/Icon';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

export enum SessionToastType {
  ERROR,
  INFO,
  SUCCESS,
}

const fadeIn = keyframes({
  'from': css`opacity: 0;`,
  'to': css`opacity: 1;`,
})

const getBackgroundColorForType = (type: SessionToastType) => {
  switch (type) {
    case SessionToastType.ERROR:
      return FC_RED;
    case SessionToastType.INFO:
      return NEUTRAL_90;
    case SessionToastType.SUCCESS:
      return FC_GREEN;
  }
}
const SessionToast = ({ style: sessionToastType, children, duration = Infinity }) => {
  const mobileView = useMediaQuery({query: '(max-width: 674px)'});
  const [show, setShow] = useState(true);
  useEffect(() => {
    let hideToast;
    if (duration !== Infinity) {
      hideToast = setTimeout(() => {
        setShow(false);
      }, duration);
    }
    return () => {
      if (hideToast) clearTimeout(hideToast);
    }
  }, [duration])
  if (!show) return null;
  return (
    <div css={css`
      position: ${mobileView ? 'fixed' : 'absolute'};
      top: 20px;
      left: 25px;
      right: 25px;
      padding: 24px 32px 24px 36px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      background-color: ${getBackgroundColorForType(sessionToastType)};
      color: #ffffff;
      animation: ${fadeIn} 0.5s ease-in-out;
      z-index: ${sessionToastType === SessionToastType.ERROR ? 1001 : 1000}; /* Our toasts don't actually stack like real toasts, so in the event we have multiple,
      show errors on top */
      left: 25px;
      right: 25px;
      width: calc(100% - 50px);
      display: flex;
    `}>
      <div css={css`flex-grow: 1;`}>
        {children}
      </div>
      <div onClick={() => setShow(false)} css={css`
        cursor: pointer;
      `}>
        <Icon
          //@ts-ignore
          type={TYPE_CLOSE_UNSET_FILL}
          css={css`
            fill: white;
            &:hover { fill: ${FC_LIGHTER_BLUE} }
            &:active { fill: ${FC_LIGHTER_BLUE} }
          `}
        /> 
      </div>
    </div>
  )
}

export const SessionToastForCallState = ({ callState }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const callStateErrorType = callState.fatalError ? "fatal" : callState.camOrMicError ? "cam/mic" : "click allow"
  const reloadPage = () => {
    sendSegmentEvent(`Reloaded page due to ${callState.fatalError ? "fatal error" : "cam/mic error"}`, { callStateErrorType })
    window.location.reload()
  }
  const visitHelp = () => {
    sendSegmentEvent("Visited help for mic/cam error", { callStateErrorType })
    window.open("https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer")
  }
  useEffect(() => {
    // Unfortunately this will fire a few times since callState changes a few times
    // For the local participant, but this is still useful to us to see how often
    // users see this
    if (callState.fatalError) {
      sendSegmentEvent("Saw fatal call error toast", { callStateErrorType })
    } else if (callState.camOrMicError) {
      sendSegmentEvent("Saw cam/mic error toast", { callStateErrorType })
    } else if (shouldShowClickAllow(callState)) {
      sendSegmentEvent("Saw click allow camera/mic toast", { callStateErrorType })
    }
  }, [callState])
  if (callState.fatalError) {
    return (
      <SessionToast style={SessionToastType.ERROR}>
        Unfortunately, we ran into an error while trying to connect to the session. Please reload the page and try again or <a css={css`color: #fff; text-decoration: underline;`} href="mailto:help@flow.club">contact support</a>.
        <FlowButton customCss={css`margin-top: 8px;`} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={reloadPage}>Reload</FlowButton>
      </SessionToast>
    )
  } else if (callState.camOrMicError) {
    return (
      <SessionToast style={SessionToastType.ERROR}>
        We weren't able to access your camera or mic. It's important to turn these on to have a good session. Please give permission and refresh the page.
        <div css={css`margin-top: 8px; display: flex; gap: 16px;`}>
          <FlowButton buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={reloadPage}>Reload</FlowButton>
          <FlowButton buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={visitHelp}>Help</FlowButton>
        </div>
      </SessionToast>
    )
  } else if (shouldShowClickAllow(callState)) {
    return (
      <SessionToast style={SessionToastType.INFO}>
        Please click "Allow" to give us permission to use your camera and microphone.
      </SessionToast>
    )
  } else {
    return null;
  }
}
