/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Alert } from 'react-bootstrap'

import {
  useContext,
  useState,
} from "react"
import {
  NEUTRAL_80,
  NEUTRAL_90,
} from '../emotionVariables'

import { db, functionsHost } from '../firebase'
import { UserContext } from '../UserProvider'
import { getDaysBetween } from '../utils'
import { BUTTON_STYLES, FlowButton, Text, TextArea, TEXT_STYLES, UnstyledLink } from '../components/flowComponents'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { toastError } from '../components/utils/toaster'
import { userEligibleForTrialExtension } from '../../functions/shared/helpers'

export const TrialExtension = () => {
  const [step2, setStep2] = useState(false)

  const { user } = useContext(UserContext)
  const timeSinceTrialEnded = Date.now() - user.trialEnd.toMillis()

  return (
    <SegmentProvider baseData={{ timeSinceTrialEnded }} eventLabel={"Trial Extension"}>
      <div css={css`
        padding: 64px 12px 0px 12px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}>
        <div css={css`
          max-width: 620px;
          text-align: center;

          display: flex;
          flex-direction: column;
          align-items: center;
        `}>
          {
            user && userEligibleForTrialExtension(user) && !user.trialExtensionSubmitted ?
              (step2 ?
              <Step2 /> :
              <Step1 goToStep2={() => setStep2(true)} />) :
              <NotEligible />
          }
        </div>
      </div>
    </SegmentProvider>
  );
};

const NotEligible = () => (
  <>
    <Alert variant="danger" css={css` color: white;`}>
      Sorry, but you aren't eligible to extend your free trial.
    </Alert>
    <UnstyledLink to={"/plans/"}>
      <FlowButton fillAvailableWidth buttonStyle={BUTTON_STYLES.DARK}>
        See plans
      </FlowButton>
    </UnstyledLink>
  </>
)


const Step1 = ({ goToStep2 }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const extendTrialClicked = () => {
    sendSegmentEvent("Extend Trial Clicked")
    goToStep2()
  }

  const notRightNowClicked = () => {
    sendSegmentEvent("Not Right Now Clicked")
  }

  return (
    <>
      <Text customCss={css`margin-bottom: 8px; color: ${NEUTRAL_80};`}>Step 1</Text>
      <Text customCss={css`margin-bottom: 16px;`} style={TEXT_STYLES.APP_H1}>Is there a task you'd like to accomplish?</Text>
      <Text customCss={css`color: ${NEUTRAL_90}; margin-bottom: 48px;`}>If there's anything you'd like to accomplish today, we'd love to extend your Flow Club trial. If not, we'll wait to extend your trial.</Text>
      <div css={css`width: 320px;`}>
        <FlowButton fillAvailableWidth customCss={css`margin-bottom: 8px;`} onClick={extendTrialClicked}>Yes, extend my trial</FlowButton>
        <UnstyledLink to={"/schedule"} onClick={notRightNowClicked}>
          <FlowButton fillAvailableWidth buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>No, not right now</FlowButton>
        </UnstyledLink>
      </div>
    </>
  )
}

const Step2 = () => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user, authUser } = useContext(UserContext)

  const [loading, setLoading] = useState(null)

  const onSubmitExtensionRequest = async (mit, mitSubmitted) => {
    if (authUser !== null) {
      setLoading({ mitSubmitted })
      const token = await authUser.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          mit
        })
      }
      const result = await fetch(functionsHost + 'trial-extend/', requestOptions)
      if (!result.ok) {
        const resp = await result.json()
        setLoading(null)
        toastError({ message: resp.error })
      } else {
        sendSegmentEvent("Extended free trial", {
          mit,
          daysSinceTrialEnd: user.trialEnd.toDate !== undefined ? getDaysBetween(user.trialEnd.toDate(), new Date()) : undefined,
          sessions: user.sessions,
        })
        window.location = '/upcoming/?trialExtended=true'
      }
    }
  }

  const [mit, setMit] = useState('')

  const submitClicked = () => {
    sendSegmentEvent("Trial Extension Requested", { mit, mitSubmitted: true })
    onSubmitExtensionRequest(mit, true)
  }

  const skipClicked = () => {
    sendSegmentEvent("Trial Extension Requested", { mitSubmitted: false })
    onSubmitExtensionRequest(mit, false)
  }

  return (
    <>
      <Text customCss={css`margin-bottom: 8px; color: ${NEUTRAL_80};`}>Step 2 (Optional)</Text>
      <Text customCss={css`margin-bottom: 16px;`} style={TEXT_STYLES.APP_H1}>Share your task</Text>
      <Text customCss={css`color: ${NEUTRAL_90}; margin-bottom: 32px;`}>Share your task with us so we can better understand how to continue making Flow Club the best for users like you.</Text>
      <div css={css`width: 320px;`}>
        <TextArea customCss={css`margin-bottom: 16px;`} value={mit} onChange={(event) => setMit(event.target.value)} />
        <FlowButton fillAvailableWidth disabled={mit.length === 0} loading={loading !== null && loading.mitSubmitted} customCss={css`margin-bottom: 8px;`} onClick={submitClicked}>Submit</FlowButton>
        <FlowButton fillAvailableWidth loading={loading !== null && !loading.mitSubmitted} buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={skipClicked}>Skip</FlowButton>
      </div>
    </>
  )
}