/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'
import { useCreateCustomizableSession, useSetUserPreferences } from '../../fetch/endpoints'
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { toastError } from '../utils/toaster';
import { getAgendaForEvent, getPrivateSessionDescriptionForTag, getTitleForEvent } from '../../../functions/shared/helpers';
import { CheckboxButton, SegmentedButton, Text, TEXT_STYLES, ToggleSwitchButton, EmojiButton, LightGrayText } from '../flowComponents';
import { BRONZE, NEUTRAL_80 } from '../../emotionVariables';
import { FancySessionTimeSelectorContext } from './FancySessionTimeSelector';
import { DarkTooltip } from '../DarkTooltip';
import { TooltipWrapper } from '../TooltipWrapper';
import { useQuery } from '../../utils';
import { usePreferences } from '../../UserProvider';
import { AgendaDescription } from '../AgendaDescription';
import { DURATION_OPTIONS } from '../../../functions/shared/constants';

const PRESET_VISIBILITIES = {
  'public': 'Public',
  'private': 'Unlisted'
}

export const CreateSessionConfirmDetail = ({
  sessionStart,
  onCreateSession,
  eventTypes,
  user,
  adminModeEnabled,
  selectedHost,
  isFullScreen,
  setDirty = () => {}
}) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const query = useQuery()
  const { refetchUpcomingSessions, creatableSessionVisibilities } = useContext(FancySessionTimeSelectorContext)
  // Assume this was loaded/updated in clubs menu?
  const cachedClubsDataForUser = localStorage.getItem('clubsDataForUser')
  const clubsDataForUser = (() => {
    try {
      return cachedClubsDataForUser ? JSON.parse(cachedClubsDataForUser) : null;
    } catch (error) {
      console.error('Error parsing clubsDataForUser:', error);
      return null;
    }
  })();

  const visibilityOptions = creatableSessionVisibilities.map(visibility => ({
    visibilityTag: visibility,
    name: PRESET_VISIBILITIES[visibility] ?? getPrivateSessionDescriptionForTag(visibility)
  }))

  const customizedSessionSettings = usePreferences().sessionCreationCustomizationSettings

  const defaultSessionSettings = {
    duration: customizedSessionSettings?.duration ?? 60,
    visibility: visibilityOptions[0].visibilityTag,
    chatOnly: false,
    pomodoro: false,
    breakCheckIns: false,
    eventTypeId: eventTypes[0].id,
    recurrences: 1,
    customizeEnabled: false,
    recentEventTypeIds: customizedSessionSettings?.recentEventTypeIds ?? [],
  }

  const preselectedVisibility = (user.tags.includes(query.get('sessionVisibility')) ? query.get('sessionVisibility') : null)
  const preselectedTheme = query.get('sessionTheme')
  const [advancedOptionsEnabled, setAdvancedOptionsEnabled] = useState(preselectedVisibility !== null || preselectedTheme !== null || (customizedSessionSettings?.customizeEnabled ?? false))

  const customizationPreferences = customizedSessionSettings !== undefined && advancedOptionsEnabled ? customizedSessionSettings : defaultSessionSettings

  // invalidating saved preferences:
  // in case user sets a preference and then loses a tag, or loses the ability to host public sessions
  if (
    !user.tags.includes(customizationPreferences.visibility) ||
    (visibilityOptions.find(({ visibilityTag }) => visibilityTag === customizationPreferences.visibility) === undefined)
  ) {
    customizationPreferences.visibility = visibilityOptions[0].visibilityTag
  }
  if (preselectedTheme) {
    customizationPreferences.eventTypeId = preselectedTheme
  }

  const [customizedEventTypeId, setCustomizedEventTypeId] = useState(customizationPreferences.eventTypeId ?? eventTypes[0].id)
  const selectedEventTypeId = advancedOptionsEnabled ? customizedEventTypeId : defaultSessionSettings.eventTypeId
  const selectedEventType = eventTypes.find(({ id }) => id === selectedEventTypeId)
  const visibilityNameForTag = tag => visibilityOptions.find(({ visibilityTag }) => visibilityTag === tag).name
  const [duration, setDuration] = useState(customizationPreferences.duration)
  const [customRecurrences, setCustomRecurrences] = useState(customizationPreferences.recurrences)
  const recurrences = advancedOptionsEnabled ? customRecurrences : defaultSessionSettings.recurrences
  const [recentEventTypeIds, setRecentEventTypeIds] = useState(defaultSessionSettings.recentEventTypeIds)
  const recentEventTypes = recentEventTypeIds.map(id => eventTypes.find(({ id: eventTypeId }) => eventTypeId === id)).filter(Boolean)
  const clubEventTypes = clubsDataForUser ? clubsDataForUser.map(club => eventTypes.find(eventType => eventType.id === club.theme))
  .filter(Boolean) : []
  const remainingEventTypes = eventTypes.filter(({ id }) => !recentEventTypeIds.includes(id) && !clubEventTypes.map(({ id }) => id).includes(id))
  const [musicDescription, setMusicDescription] = useState("")
  const [hostGoalDescription, setHostGoalDescription] = useState("")
  const [hostGoalEmoji, setHostGoalEmoji] = useState("🥅")
  const [customizedVisibility, setCustomizedVisibility] = useState(preselectedVisibility ?? customizationPreferences.visibility)
  const visibility = advancedOptionsEnabled ? customizedVisibility : defaultSessionSettings.visibility

  const [customizedChatOnlyEnabled, setCustomizedChatOnlyEnabled] = useState(customizationPreferences.chatOnly)
  const chatOnly = advancedOptionsEnabled ? customizedChatOnlyEnabled : defaultSessionSettings.chatOnly
  const [customizedPomodoroEnabled, setCustomizedPomodoroEnabled] = useState(customizationPreferences.pomodoro)
  const [customizedBreakCheckInsEnabled, setCustomizedBreakCheckInsEnabled] = useState(customizationPreferences.breakCheckIns)
  const pomodoro = advancedOptionsEnabled && duration > 30 ? customizedPomodoroEnabled : defaultSessionSettings.pomodoro
  const breakCheckIns = advancedOptionsEnabled && (duration > 90 || pomodoro) ? customizedBreakCheckInsEnabled : defaultSessionSettings.breakCheckIns


  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const updateCustomizationPreferences = () => {

    setRecentEventTypeIds([selectedEventTypeId, ...recentEventTypeIds.filter(id => id !== selectedEventTypeId)].slice(0, 5))
    // this seems to make the popup visually stutter a bit (from useSetUserPreferences return values updating? not sure) while it's appearing which looks a little jank, so just delay this call slightly for the sake of visual smoothness
    setTimeout(() => {
      const updatedRecentEventTypeIds = [selectedEventTypeId, ...recentEventTypeIds.filter(id => id !== selectedEventTypeId)].slice(0, 5)
      setUserPreferences({
        newPreferenceData: {
          sessionCreationCustomizationSettings: {
            duration,
            visibility: customizedVisibility,
            chatOnly: customizedChatOnlyEnabled,
            pomodoro: customizedPomodoroEnabled,
            breakCheckIns: customizedBreakCheckInsEnabled,
            recentEventTypeIds: updatedRecentEventTypeIds,
            recurrences: customRecurrences,
            customizeEnabled: advancedOptionsEnabled,
          },
        },
      });
    }, 1000);
  }
  useEffect(updateCustomizationPreferences, [duration, customizedVisibility, customizedChatOnlyEnabled, customizedPomodoroEnabled, customizedEventTypeId, customRecurrences, advancedOptionsEnabled])

  const eventTypeChanged = event => {
    setCustomizedEventTypeId(event.target.value)
    sendSegmentEvent("Event Type Changed", { eventTypeId: event.target.value, eventTypeTitle: eventTypes.find(({ id }) => id === event.target.value).title })
  }
  const musicDescriptionChanged = () => {
    setDirty(true)
    sendSegmentEvent("Music Description Changed", { musicDescription })
  }
  const hostGoalDescriptionChanged = () => {
    setDirty(true)
    sendSegmentEvent("Host Goal Description Changed", { hostGoalDescription })
  }
  const onEmojiSelect = (emoji) => {
    setDirty(true)
    setHostGoalEmoji(emoji)
    sendSegmentEvent("Host Goal Emoji Selected", { emoji })
  }
  const recurrencesChanged = newRecurrences => {
    setCustomRecurrences(newRecurrences);
    sendSegmentEvent("Recurrences Changed", { recurrences: newRecurrences })
  }
  const visibilityChanged = event => {
    setCustomizedVisibility(event.target.value);
    sendSegmentEvent("Visibility Changed", { visibility: event.target.value })
  }
  const durationChanged = newDuration => {
    setDuration(newDuration);
    sendSegmentEvent("Duration Changed", { duration: newDuration })
  }
  const customizeToggled = (togglingTo) => {
    sendSegmentEvent("Customize Mode Toggled", { togglingTo })
    setAdvancedOptionsEnabled(togglingTo)
  }
  const chatOnlyToggled = (togglingTo) => {
    sendSegmentEvent("Chat-Only Toggled", { togglingTo })
    setCustomizedChatOnlyEnabled(togglingTo)
  }

  const breakCheckInsToggled = (togglingTo) => {
    sendSegmentEvent("Break Check-Ins Toggled", { togglingTo })
    setCustomizedBreakCheckInsEnabled(togglingTo)
  }
  const pomodoroToggled = (togglingTo) => {
    sendSegmentEvent("Pomodoro Toggled", { togglingTo })
    setCustomizedPomodoroEnabled(togglingTo)
  }

  // for admin controls
  // -- manually entered start time to use if in admin scheduling mode
  const defaultTimeOverride = sessionStart.format('MM/DD/YY HH:mm')
  const [startTimeOverride, setStartTimeOverride] = useState(defaultTimeOverride)
  // "valid date" here is a very generous term, but roll with it
  const startTimeOverrideIsValid = dayjs(startTimeOverride).isValid() && startTimeOverride.match(/^[0-1][0-9]\/[0-3][0-9]\/[0-9][0-9] [0-2][0-9]:[0-6][0-9]$/) !== null
  let effectiveDate = adminModeEnabled ? (startTimeOverrideIsValid ? dayjs.tz(startTimeOverride, "MM/DD/YY HH:mm", selectedHost.timezone) : null) : sessionStart
  if (effectiveDate !== null) {
    const startOfDayMinutesDSTOffset = effectiveDate.startOf('day').utcOffset() - effectiveDate.add(1, 'day').startOf('day').utcOffset() // thanks DST
    effectiveDate = effectiveDate.add(startOfDayMinutesDSTOffset, 'minute')
  }

  const { performFetch: createSession, fetching: creating, result, error } = useCreateCustomizableSession()
  const submitForm = async () => {
    const customizationOptions = { duration, visibility, chatOnly, breakCheckIns, pomodoro }
    setDirty(true)
    const { success, result, error } = await createSession({
      startTime: effectiveDate.toDate().toISOString(),
      eventTypeId: selectedEventTypeId,
      musicDescription,
      hostGoalDescription,
      hostGoalEmoji,
      recurrences: parseInt(recurrences, 10),
      hostId: selectedHost.id,
      customizationOptions
    })

    if (success) {
      if (result.errors.length < recurrences) {
        onCreateSession(result.createdSessionIds[0], recurrences, result.ogImage, hostGoalDescription, hostGoalEmoji)
      }
      refetchUpcomingSessions()
      result.errors.forEach(error => toastError({ message: `Error while attempting to create session at ${dayjs(error.intendedStartTime).format('MM/DD/YY HH:mm')}: ${error.error}` }))
      sendSegmentEvent("Created session", {
        selectedEventTypeId: selectedEventTypeId,
        recurring: recurrences,
        ...customizationOptions,
        offHour: sessionStart.minute() !== 0,
      })
    }
    else {
      toastError({ message: error })
    }
  }

  const successfullyCreated = result !== null && error === null && result.errors.length < recurrences

  const privateSession = visibility === 'private'

  const recurringOptions = [1, 2, 3, 4].map(r => ({
    value: r,
    name: `${r}x`,
    active: customRecurrences === r,
  }))

  const durationOptions = DURATION_OPTIONS.map(durationOption => ({
    value: durationOption,
    name: `${durationOption}m`,
    active: duration === durationOption,
  }))

  const agenda = getAgendaForEvent(duration, pomodoro, breakCheckIns)

  return (
    <Container style={{ maxWidth: "391px" }}>
      <Row>
        <Col className="d-flex justify-content-center text-center">
          <div css={css`margin-bottom: 12px; flex-direction: column; display: flex; align-items: center;`}>
            <Text style={TEXT_STYLES.APP_H5} customCss={css`margin-bottom: 16px;`}>{getTitleForEvent(duration, pomodoro, chatOnly, customizedVisibility, selectedEventType)}{recurrences > 1 && <LightGrayText customCss={css`font-size: 14px;`}>Recurring for {recurrences} weeks</LightGrayText>}</Text>
            <div css={css`display: flex; align-items: center; gap: 4px;`}>
              <ToggleSwitchButton selected={advancedOptionsEnabled} setSelected={customizeToggled} />
              <Text style={TEXT_STYLES.BODY_2}>Customize</Text>
            </div>
            {isFullScreen && <Text customCss={css`margin-top: 12px;`}>
              {`${effectiveDate.format("dddd, MMMM DD • hh:mm A")} – ${effectiveDate.add(duration, 'minute').format("hh:mm A z")}`}
            </Text>}
          </div>
        </Col>
      </Row>
      {adminModeEnabled &&
        <div css={css`margin-top: 24px;`}>
          <StartTimeOverride startTimeOverride={startTimeOverride} startTimeOverrideIsValid={startTimeOverrideIsValid} setStartTimeOverride={setStartTimeOverride} timezone={selectedHost.timezone} />
        </div>
      }
      <Form.Group>
        <Form.Label>Duration{advancedOptionsEnabled ? ' and Structure' : ''}</Form.Label>
        <SegmentedButton options={durationOptions} onOptionToggled={durationChanged} customItemCss={css`padding: 10px;`} />
      </Form.Group>
      {advancedOptionsEnabled &&
        <div css={css`display: flex; margin-bottom: 24px; gap: 8px;`}>
          <div css={css`flex-shrink: 0; flex: 1; min-width: 155px;`}>
            <AgendaDescription agenda={agenda} />
          </div>
          <div css={css`flex-shrink: 1; flex: 1; display: flex; flex-direction: column; gap: 12px;`}>
            <TooltipWrapper retainMousedOverInsideTooltip={false} TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'top' }} TooltipContents={'Typed updates only; no talking out loud in-session'}>
              <CheckboxButton selected={customizedChatOnlyEnabled} setSelected={chatOnlyToggled}><Text style={TEXT_STYLES.BODY_2} customCss={css`font-weight: bold;`}>Chat-Only</Text></CheckboxButton>
            </TooltipWrapper>
            {duration > 30 && <CheckboxButton selected={customizedPomodoroEnabled} setSelected={pomodoroToggled}><Text style={TEXT_STYLES.BODY_2} customCss={css`font-weight: bold;`}>Pomodoro Structure</Text></CheckboxButton>}
            {(duration > 90 || pomodoro) &&<TooltipWrapper retainMousedOverInsideTooltip={false} TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'top' }} TooltipContents={'Host checks in with everyone during breaks'}>
              <CheckboxButton selected={customizedBreakCheckInsEnabled} setSelected={breakCheckInsToggled}><Text style={TEXT_STYLES.BODY_2} customCss={css`font-weight: bold;`}>Check in during breaks</Text></CheckboxButton>
            </TooltipWrapper>}
          </div>
        </div>
      }
      <Form.Group css={css`margin-bottom: 12px;`}>
        <Form.Label>Music Description (optional)</Form.Label>
        <input
          type="text"
          className="form-control"
          value={musicDescription}
          onChange={event => setMusicDescription(event.target.value)}
          onBlur={musicDescriptionChanged}
          placeholder='e.g. Lo-fi, Mozart, Movie Soundtracks, Tycho'
          maxLength={40}
        />
      </Form.Group>
      <Form.Group>
       <Form.Label>What you're working on (optional)</Form.Label>
         <div css={css`display: flex; flex-direction: row; gap: 8px`}>
          <EmojiButton
            onEmojiSelect={onEmojiSelect}
            selectedEmoji={hostGoalEmoji}
          />
          <input
            type="text"
            className="form-control"
            value={hostGoalDescription}
            onChange={event => setHostGoalDescription(event.target.value)}
            onBlur={hostGoalDescriptionChanged}
            placeholder='e.g. Finish draft, Plan day, Clear inbox'
            maxLength={40}
          />
          </div>
      </Form.Group>
      {advancedOptionsEnabled &&
        <>
          <Form.Group controlId="eventType" css={css`margin-top: 24px; margin-bottom: 8px;`}>
            <Form.Label>Theme</Form.Label>
            <div css={preselectedTheme !== null && css`
              box-shadow: 0px 0px 3px 4px ${BRONZE};
              border-radius: 4px;
            `}>
            <Form.Control
              as="select"
              className="fc-select dynamic-size"
              name="eventType"
              value={customizedEventTypeId}
              onChange={eventTypeChanged}
            >
              {recentEventTypes.length > 0 && <optgroup label="Recently used">
                {recentEventTypes.map(({ id, title }, index) => (
                  <option value={id} key={id}>{title}</option>
                ))}
              </optgroup>}
              {clubEventTypes && <optgroup label="My Clubs">
                {clubEventTypes.map(({ id, title }, index) => (
                  <option value={id} key={id}>{title}</option>
                ))}
              </optgroup>}
              {remainingEventTypes.length > 0 && <optgroup label="Join these clubs to create themes" disabled>
              {remainingEventTypes.map(({ id, title }, index) => (
                <option value={id} key={id}>{title}</option>
              ))}
              </optgroup>}
            </Form.Control>
          </div>
          </Form.Group>
          <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${NEUTRAL_80};`}>{selectedEventType.description}</Text>
          <Form.Group css={css`margin-top: 24px; margin-bottom: 8px;`}>
            <Form.Label>Visibility</Form.Label>
            <div css={preselectedVisibility !== null && css`
              box-shadow: 0px 0px 3px 4px ${BRONZE};
              border-radius: 4px;
            `}>
              <Form.Control
                as="select"
                className="fc-select dynamic-size"
                name="visibility"
                value={customizedVisibility}
                onChange={visibilityChanged}
              >
                {visibilityOptions.map(({ visibilityTag }) => (
                  <option value={visibilityTag} key={visibilityTag}>{visibilityNameForTag(visibilityTag)}</option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
          {privateSession && <PrivateSessionIndicator />}
          <Form.Group controlId="recurrence" style={{ marginTop: "24px" }}>
            <Form.Label>Recurring (weekly)</Form.Label>
            <SegmentedButton options={recurringOptions} onOptionToggled={recurrencesChanged} />
          </Form.Group>
        </>
      }

      {error !== null ?
        <Row className="mt-5">
          <Col className="d-flex justify-content-center">
            <Alert variant="danger" css={css` color: white;`}>
              We weren't able to create your session. Please try again or contact <a href="mailto:hosts@flow.club">hosts@flow.club</a> and we'll get this resolved.
            </Alert>
          </Col>
        </Row> : ""}
      <Row style={{ marginTop: "48px" }}>
        <Col className="d-flex justify-content-center">
          <Button
            variant="primary"
            disabled={creating || successfullyCreated || (startTimeOverride !== "" && !startTimeOverrideIsValid)}
            onClick={submitForm}
            css={privateSession && css`background-color: ${BRONZE}; border-color: ${BRONZE};`}
          >
            {creating ? "Creating..." :
              successfullyCreated ? "Done!" :
                (selectedHost.id !== user.uid) ? "Create Session" : "Create My Session"}
          </Button>
        </Col>
      </Row>

    </Container>
  )
}

export const StartTimeOverride = ({ startTimeOverride, startTimeOverrideIsValid, setStartTimeOverride, timezone, adminBackground = true }) => {
  const background = adminBackground ?
    css`
    border-radius: 8px;
    border: 1px solid rgba(160, 187, 217, 0.2);
    padding: 8px;

    background: repeating-linear-gradient(
      -45deg,
      rgba(160, 187, 217, 0.2),
      rgba(160, 187, 217, 0.2) 30px,
      transparent 30px,
      transparent 60px
    );
  ` : null

  return (
    <div css={background}>
      <Form.Label>Start Time</Form.Label>
      <input
        type="text"
        className="form-control"
        value={startTimeOverride}
        onChange={(event) => setStartTimeOverride(event.target.value)}
        placeholder={"mm/dd/yy hh:mm"}
        style={startTimeOverrideIsValid ? { color: "black", fontWeight: "bold" } : {}}
      />
      <StartTimeOverrideHelpText>Must be in the form <span css={css`font-weight: bold;`}>mm/dd/yy hh:mm</span> (24 hour time).</StartTimeOverrideHelpText>
      <StartTimeOverrideHelpText>This time will be interpreted in the time zone of the <strong>host for the session.</strong> (currently <strong>{dayjs().tz(timezone).format("z, zzz")}</strong>)</StartTimeOverrideHelpText>
    </div>
  )
}

const StartTimeOverrideHelpText = ({ children }) => {
  return (
    <div css={css`
      margin: 4px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    `}>
      {children}
    </div>
  )
}

const PrivateSessionIndicator = () => {
  return (
    <div>
      <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${BRONZE};`}>Only those with the link can join.</Text>
      <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${BRONZE};`}>It will NOT appear on the Flow Club Schedule nor count towards your Host Incentive.</Text>
    </div>
  )
}