/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs'
import { useContext, useMemo, memo } from "react";
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext, useGetDisplayName } from '../UserProvider';
import { SessionInfo } from './SessionInfo';
import { BookingButtonWrapper } from '../components/BookingButtonWrapper';
import HoverablePopover from '../components/hoverable-popover';
import { HostInfoTooltip } from '../components/host-info-tooltip';
import { FC_GREEN, FC_LIGHT_GREEN, FC_IRIS_BLUE, FC_LIGHTER_BLUE, NEUTRAL_100, NEUTRAL_40, NEUTRAL_60, NEUTRAL_80, NEUTRAL_90 } from '../emotionVariables';
import { Text, TEXT_STYLES, UnstyledLink } from '../components/flowComponents';
import { EMPTY_HOST_ID, sessionIsInteractable } from './SessionUtils';
import { getSecondaryInteractionsAvailable, SecondaryInteractionOptions } from './SecondaryInteractionOptions';
import { useMediaQuery } from 'react-responsive';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { getImageForHost, getSessionIsHappeningNow, normalizeSlug } from '../../functions/shared/helpers';
import Icon, { TYPE_CLIPBOARD } from '../daily/daily-components/Icon/Icon';
import  { copyToClipboard } from '../utils';
import { parseGoalsToMarkdown, ChecklistIconTooltip } from '../components/GoalList/GoalListWithCopyPaste';
import { OutOfSessionProgressBars } from '../ParticipantApp/SessionStageProgressBars';

const HappeningNowDot = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill={FC_GREEN}/>
    <circle cx="4" cy="4" r="2" fill={FC_LIGHT_GREEN} />
  </svg>
)

const StartingSoonClock = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 0.625C7.09961 0.625 8.57617 1.48633 9.38477 2.875C10.1934 4.28125 10.1934 5.98633 9.38477 7.375C8.57617 8.78125 7.09961 9.625 5.5 9.625C3.88281 9.625 2.40625 8.78125 1.59766 7.375C0.789062 5.98633 0.789062 4.28125 1.59766 2.875C2.40625 1.48633 3.88281 0.625 5.5 0.625ZM5.07812 2.73438V5.125C5.07812 5.26562 5.14844 5.40625 5.25391 5.47656L6.94141 6.60156C7.13477 6.74219 7.39844 6.68945 7.53906 6.49609C7.66211 6.30273 7.60938 6.03906 7.41602 5.89844L5.92188 4.91406V2.73438C5.92188 2.50586 5.72852 2.3125 5.5 2.3125C5.25391 2.3125 5.07812 2.50586 5.07812 2.73438Z" fill="#525EA8"/>
  </svg>
)

export const ClubWithLink = memo(({eventTypeId, style })  => {
  if (eventTypeId === 'Classic' || eventTypeId === undefined ) {
    return null
  }

  const link = `/club/${normalizeSlug(eventTypeId)}`
  const clubName = eventTypeId
  return (
    <Link to={link}>
      {style === 'badge' ?
        <Badge css={css`background-color: #D5E3E0; color: #000;`} className="mr-2">{clubName}</Badge>
        :
        <span css={css`color: ${FC_GREEN}`}>
        {clubName} ►&nbsp;
        </span>
      }
    </Link>
  )
})

export const EventCard = ({
  event,
  isFirst = true,
  isLast = true,
  showDate = false,
  showTime = true,
  sessionIsPast = false,
  showToolTip = true,
  onActionSuccess = () => {},
  forceMobileView = false,
  displayRecommendedSessionType = false,
  hideButton = false,
  embed = false,
  onSessionClick = () => {},
}) => {
  const { user } = useContext(UserContext)
  const { isFakeHostTBDSession, tags: eventTags, userSessionStatus, hostId, hostUser, participant, start, id: eventId, title, customAgendaDescription, subtitle, eventTypeId } = event

  const titleWithThemeStripped = eventTypeId === 'Classic' ? title : title.replace(`${eventTypeId} `, '')

  const tags = eventTags ?? []

  const enabled = sessionIsInteractable(userSessionStatus)

  const showHostAvatarTooltip = showToolTip && hostId !== EMPTY_HOST_ID
  const userWasHost = sessionIsPast && hostId === user.uid

  const mobileView = useMediaQuery({ query: '(max-width: 767px)' }) || forceMobileView

  const completedSessionDataPresent = (participant !== undefined) && (
    (participant.goals !== undefined && participant.goals.length > 0) ||
    (participant.goal !== undefined && participant.goal.length > 0) ||
    (participant.flowScore !== undefined)
  )


  const eventWithAdjustedStart = { ...event, start: new Date(start) }

  const sessionHappeningNow = getSessionIsHappeningNow(userSessionStatus)

  const recommendedSessionType = sessionHappeningNow ? "Happening now" : "Starting soon"

  // Don't link to session details page if session doesn't actually exist
  const LinkWrapper = useMemo(() => ({ children }) => !isFakeHostTBDSession ?
    <UnstyledLink to={"/session/" + eventId} target={embed ? "_top" : "_self"} rel="noopener noreferrer" onClick={onSessionClick}>{children}</UnstyledLink> :
    children,
  [isFakeHostTBDSession, eventId])

  return (
    <SegmentProvider>
      <LinkWrapper>
        <div
          css={css`
            background-color: #ffffff;
            border: 2px solid #e4e4e4;
            border-radius: ${isFirst ? "12px 12px" : "0px 0px"}
              ${isLast ? "12px 12px" : "0px 0px"};
            ${!isLast &&
            css`
              border-bottom: none;
            `}
            padding: 24px;
            cursor: pointer;
            &:hover {
              background-color: #f3f8ff;
            }
            text-align: initial;

            ${isFakeHostTBDSession &&
            css`
              cursor: inherit;
              &:hover {
                background-color: #ffffff;
              }
            `}

            ${!enabled &&
            css`
              cursor: inherit;
              background: #f4f4f4;
              &:hover {
                background: #f4f4f4;
              }
              img {
                opacity: 0.8;
              }
            `}
          `}
        >
          <div>
            {mobileView && (
              <div
                css={css`
                  margin-bottom: 16px;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: top;
                `}
              >
                <TimeAndDate
                  startTime={eventWithAdjustedStart.start}
                  showDate={showDate}
                  showTime={showTime}
                  enabled={enabled}
                />
                {displayRecommendedSessionType && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    {sessionHappeningNow ? (
                      <HappeningNowDot />
                    ) : (
                      <StartingSoonClock />
                    )}
                    <span
                      css={css`
                        color: ${sessionHappeningNow ? FC_GREEN : FC_IRIS_BLUE};
                      `}
                    >
                      {recommendedSessionType}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div
              css={css`
                display: flex;
              `}
            >
              {!mobileView && (
                <div
                  css={css`
                    margin-right: 16px;
                    display: flex;
                  `}
                >
                  <TimeAndDate
                    startTime={eventWithAdjustedStart.start}
                    showDate={showDate}
                    showTime={showTime}
                    enabled={enabled}
                  />
                  <div
                    css={css`
                      width: 64px;
                      height: 64px;
                    `}
                  >
                    <HostAvatar
                      showHostAvatarTooltip={showHostAvatarTooltip}
                      hostUser={hostUser}
                    />
                  </div>
                </div>
              )}
              <div
                css={css`
                  flex-grow: 1;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <div css={css`width: 100%;`}>
                    <div
                      css={css`
                        display: flex;
                        margin-bottom: 2px;
                        gap: 4px;
                      `}
                    >
                      <Text
                        customCss={css`
                          font-size: 20px;
                          color: ${enabled ? NEUTRAL_100 : NEUTRAL_80};
                          font-weight: bold;
                        `}
                      >
                        <ClubWithLink eventTypeId={eventTypeId} />
                        {titleWithThemeStripped}
                      </Text>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 4px;
                        margin-bottom: ${mobileView ? "12px" : "6px"};
                      `}
                    >
                      {mobileView && (
                        <div
                          css={css`
                            width: 32px;
                            height: 32px;
                          `}
                        >
                          <HostAvatar
                            showHostAvatarTooltip={false}
                            hostUser={hostUser}
                          />
                        </div>
                      )}
                      <Text
                        customCss={css`
                          color: ${enabled ? NEUTRAL_90 : NEUTRAL_60};
                        `}
                      >
                        <HostInlineDisplayName
                          hostUser={hostUser}
                          showTooltip={showHostAvatarTooltip}
                        />
                        {userWasHost ? " (you) Thanks for hosting! 🙏" : ""}
                      </Text>
                    </div>
                    {customAgendaDescription ?
                    <Text
                      style={TEXT_STYLES.BODY_2}
                      customCss={css`
                        color: ${NEUTRAL_80};
                      `}
                    >
                      {customAgendaDescription}
                    </Text>
                    :
                    <OutOfSessionProgressBars stages={event.agenda} />
                    }
                    <SessionInfo
                      event={eventWithAdjustedStart}
                      user={user}
                      inviteTag={null}
                      invitedBy={null}
                      guestPass={null}
                    />
                    {tags.length > 0 && (
                      <div
                        css={css`
                          display: flex;
                          gap: 4px;
                          margin-top: 20px;
                        `}
                      >
                        {tags.map((tag) => (
                          <Badge
                            css={css`background-color: #D5E3E0; margin`}
                            key={tag}
                          >
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                  {!mobileView && !sessionIsPast && !hideButton && (
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        margin-left: auto;
                        padding-left: 8px;
                        flex-shrink: 0;
                      `}
                    >
                      <BookingButtonWrapper
                        event={eventWithAdjustedStart}
                        onActionSuccess={onActionSuccess}
                      />
                      {displayRecommendedSessionType && (
                        <div
                          css={css`
                            margin-top: 8px;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          `}
                        >
                          {sessionHappeningNow ? (
                            <HappeningNowDot />
                          ) : (
                            <StartingSoonClock />
                          )}
                          <span
                            css={css`
                              color: ${sessionHappeningNow
                                ? FC_GREEN
                                : FC_IRIS_BLUE};
                            `}
                          >
                            {recommendedSessionType}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {getSecondaryInteractionsAvailable(userSessionStatus) && (
                  <div
                    css={css`
                      margin-top: 20px;
                      border-top: 1px solid ${NEUTRAL_40};
                      padding-top: 20px;
                      display: flex;
                      justify-content: flex-end;
                    `}
                  >
                    <SecondaryInteractionOptions
                      event={eventWithAdjustedStart}
                      onActionSuccess={onActionSuccess}
                    />
                  </div>
                )}
                {completedSessionDataPresent && (
                  <div
                    css={css`
                      margin-top: 20px;
                      border-top: 1px solid ${NEUTRAL_40};
                      padding-top: 20px;
                    `}
                  >
                    <CompletedSessionData
                      participant={participant}
                      event={event}
                    />
                  </div>
                )}
                {mobileView && !sessionIsPast && !hideButton && (
                  <div
                    css={css`
                      margin-top: 16px;
                      display: flex;
                      justify-content: flex-end;
                    `}
                  >
                    <BookingButtonWrapper
                      event={eventWithAdjustedStart}
                      onActionSuccess={onActionSuccess}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LinkWrapper>
    </SegmentProvider>
  );
}

export const HostAvatar = ({ showHostAvatarTooltip, hostUser }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const hostDisplayName = useGetDisplayName(hostUser)
  const onMouseEnter = () => {
    sendSegmentEvent("Host Info Tooltip Shown", { hostName: hostUser.displayName, element: 'Host image'})
  }

  return showHostAvatarTooltip ?
    <HoverablePopover
      placement='bottom'
      delay={0}
      flip={true}
      popoverClass="host-popover"
      component={HostInfoTooltip(hostUser)}
      onMouseEnter={onMouseEnter}
    >
      <img src={getImageForHost(hostUser, 'image64')} css={css`width: 100%; height: 100%; border-radius: 100%;`} alt={hostDisplayName} />
    </HoverablePopover> :
    <img src={getImageForHost(hostUser, 'image64')} css={css`width: 100%; height: 100%; border-radius: 100%;`} alt={hostDisplayName} />
}

export const HostInlineDisplayName = ({ hostUser, showTooltip }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const onMouseEnter = () => {
    sendSegmentEvent("Host Info Tooltip Shown", { hostName: hostUser.displayName, element: 'Host name' })
  }
  const hostDisplayName = useGetDisplayName(hostUser)

  return showTooltip ?
  <HoverablePopover
      placement='bottom'
      delay={0}
      flip={true}
      popoverClass="host-popover"
      component={HostInfoTooltip(hostUser)}
      onMouseEnter={onMouseEnter}
    >
      <span>{hostDisplayName}</span>
    </HoverablePopover> :
  <span>{hostDisplayName}</span>
}

const TimeAndDate = ({ startTime, showDate, showTime, enabled }) => {
  if (!showTime && !showDate) {
    return null
  }
  
  return (
    <div css={css`
      width: 120px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
    `}>
      {showTime && <Text customCss={css`font-size: 20px; color: ${enabled ? NEUTRAL_100 : NEUTRAL_80}; font-weight: bold;`}>{dayjs(startTime).format("h:mm A")}</Text>}
      {showDate && <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${NEUTRAL_80};`}>{dayjs(startTime).format("ddd, MMM DD")}</Text>}
    </div>
  )
}

export const CompletedSessionData = ({ participant, event }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const onMusicLinkClick = (e) => {
    sendSegmentEvent("Music Link Clicked", { musicLink: event.musicLink })
    e.stopPropagation()
  }

  const copyToClipboardClicked = async (e, goals) => {
    e.stopPropagation()
    e.preventDefault();
    copyToClipboard(parseGoalsToMarkdown(goals.filter(goal => !goal.completedAt)), { onSuccessMessage: "Successfully copied unfinished goals to clipboard!" })
    sendSegmentEvent("Copied Goals from past session")
  }

  const LinkWrapper = useMemo(() => ({ children }) => event.musicLink !== null ?
    <a href={event.musicLink} onClick={onMusicLinkClick} target="_blank" rel="noreferrer noopener">{children}</a> : children)

  return (
    <div css={css`display: flex; flex-wrap: wrap; gap: 20px;`}>
      {event.music !== undefined &&
        <LinkWrapper>
          <div>
          <Text style={TEXT_STYLES.OVERLINE} customCss={css`margin-bottom: 8px;`}>PLAYLIST</Text>
          <Text>{event.music}</Text>
          </div> 
        </LinkWrapper>
      }
      {participant.flowScore !== undefined &&
        <div>
          <Text style={TEXT_STYLES.OVERLINE} customCss={css`margin-bottom: 8px;`}>FLOW SCORE</Text>
          <Text style={TEXT_STYLES.CAPTION}>{participant.flowScore}</Text>
        </div>
      }
      {participant.goals !== undefined &&
        <div>
          <div css={css`display: flex; flex-direction: row; justify-content: space-between; gap: 24px; align-items: center;`}>
            <Text style={TEXT_STYLES.OVERLINE} customCss={css`margin-bottom: 8px;`}>GOALS</Text>
            <div onClick={(e) => copyToClipboardClicked(e, participant.goals)} css={css`
              cursor: pointer;
              &:hover {
                background: ${FC_LIGHTER_BLUE};
              }
            `}>
            <ChecklistIconTooltip TooltipContents={<Text style={TEXT_STYLES.CAPTION}>{'Copy unfinished tasks'}</Text>}>
              <div css={css`padding: 8px; margin-top: -8px;`}>
                <Icon type={TYPE_CLIPBOARD} css={css`fill: ${FC_IRIS_BLUE}; width: 16px; height: 16px;`} />
              </div>
            </ChecklistIconTooltip>
            </div>
          </div>
          <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
            {participant.goals.map((goal, index) => <Text style={TEXT_STYLES.CAPTION} key={index}>
              {goal.completedAt !== null ? '✅' : '☐'} {goal.text}
            </Text>)}
          </div>
        </div>
      }
      {participant.goal !== undefined &&
        <div>
          <Text style={TEXT_STYLES.OVERLINE} customCss={css`margin-bottom: 8px;`}>GOALS</Text>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {participant.goal}
          </ReactMarkdown>
        </div>
      }
    </div>
  )
}