import {
  useContext,
} from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { UserContext } from '../UserProvider';
import AuthedRoute from '../AuthedRoute';

import Schedule from '../Sessions/Schedule/Schedule';
import EmbedSessions from '../Sessions/Schedule/EmbedSessions';
import Invite from '../Invite';
import Session from '../Sessions/Session';
import { MyFlowClub } from '../Sessions/MyFlowClub/MyFlowClub';
import Settings from '../components/settings/settings'
import { TrialExtension } from '../TrialExtension/TrialExtension';
import { useQuery } from '../utils';
import Membership from '../membership/membership'
import GuestPass from '../GuestPass'
import { Plans } from '../plans/plans'
import ParticipantApp from '../ParticipantApp/ParticipantApp';
import { RequestATheme } from '../RequestATheme';
import { HostProfile, HostProfileRedirect } from '../HostProfile';
import { Login } from '../EntryPage/Login';
import { LogoutPage } from '../auth/logout';
import { ResetPassword } from '../EntryPage/ResetPassword';
import { ResetPasswordAction } from '../EntryPage/ResetPasswordAction';
import { Lounge } from '../Lounge/Lounge';
import { WelcomeInterstitial } from '../Onboarding/WelcomeInterstitial';
import { ComponentsDemo } from '../ComponentsDemo';
import { SessionFinder } from '../SessionFinder';
import { HomeOnboarding } from '../HomeOnboarding/HomeOnboarding';
import { TimeOnboarding } from '../TimeOnboarding/TimeOnboarding';
import { IntentionOnboarding } from '../IntentionOnboarding/IntentionOnboarding';
import { UsageReport } from '../admin/UsageReport';
import { SignUp } from '../EntryPage/SignUp';
import { ClubsDirectory } from '../ClubsDirectory';
import { Club } from '../Club';
import { HostOnboarding } from '../HostOnboarding';
import { UserBadgeStats } from '../admin/UserBadgeStats';
import { HostFollowUnsubscribe } from '../HostFollowUnsubscribe';
import { DemoSession } from '../ParticipantApp/DemoSession';
import { InstallSlack } from '../InstallSlack';
import { SlackMessagesList } from '../admin/SlackMessagesList';
import { DashboardApp } from './DashboardApp';
import { PostSession } from '../PostSession/PostSession';
import { ReferralsLeaderboard } from '../admin/ReferralsLeaderboard';
import { AdminDashboard } from '../admin/AdminDashboard';
import { CreateSession } from '../components/create-session/CreateSession';
import { BadgeClub } from '../BadgeClub';
import { PhoneFreeParticipantSession } from '../ParticipantApp/DoNotDisturb/PhoneFree';
import { HostDirectory } from '../HostDirectory';
import { ClubsOverviewAdmin } from '../admin/ClubsOverviewAdmin';
import { ThemeClubsAdmin } from '../admin/ThemeClubsAdmin';
import { MyGoals } from '../MyGoals'

const ExternalApp = ({ children: routes }) => {
  const query = useQuery();
  const redirectAfterLogin = query.get('redirect')  || '/upcoming'

  const { user, loginInProgress } = useContext(UserContext);
  if (user !== null && !loginInProgress) {
    return <Redirect to={redirectAfterLogin} />
  }

  return routes
}

export const Routes = () => {
  return (
  <Switch>
    <Route path={["/login/", "/signup/", "/reset-password/", "/reset-password/action"]}>
      <ExternalApp>
        <Switch>
          <Route path={["/login/"]} component={Login}/>
          <Route path={["/signup/"]} component={SignUp}/>
          <Route path={["/reset-password/action"]} component={ResetPasswordAction}/>
          <Route path={["/reset-password/"]} component={ResetPassword}/>
        </Switch>
      </ExternalApp>
    </Route>
    <Route path="/logout/">
      <LogoutPage />
    </Route>
    <AuthedRoute path="/welcome">
      <WelcomeInterstitial/>
    </AuthedRoute>
    <AuthedRoute path="/s/:sessionId/post-session/">
      <PostSession />
    </AuthedRoute>
    <AuthedRoute path="/s/:sessionId">
       <ParticipantApp />
    </AuthedRoute>
    <AuthedRoute path="/j/:sessionId">
      <ParticipantApp enableSignuplessJoining />
    </AuthedRoute>
    <Route path="/dnd/:dndSessionId">
      <PhoneFreeParticipantSession />
    </Route>
    <AuthedRoute requireSignedIn path="/demo-session/">
      <DemoSession />
    </AuthedRoute>
    <AuthedRoute requireSignedIn path="/membership/:period?">
      <Membership />
    {/* SUPER IMPORTANT TO HAVE /host/:hostNameSlug BEFORE /host/ */}
    </AuthedRoute>
    <AuthedRoute requireAdmin path="/components">
      <ComponentsDemo />
    </AuthedRoute>
    <AuthedRoute path="/embed-sessions/">
      <EmbedSessions />
    </AuthedRoute>
    <Route>
      <DashboardApp>
        <Switch>
          <Route path="/home-onboarding">
            <HomeOnboarding />
          </Route>
          <Route path="/time-onboarding">
            <TimeOnboarding />
          </Route>
          <Route path="/intention-onboarding">
            <IntentionOnboarding />
          </Route>
          {/* SUPER IMPORTANT TO HAVE /upcoming/create before /upcoming/:host? */}
          <AuthedRoute requireSignedIn path="/upcoming/create/">
            <CreateSession />
          </AuthedRoute>
          <AuthedRoute path="/upcoming/:host?">
            <Schedule />
          </AuthedRoute>
          {/* this /invite/ path exists for legacy purposes -- redirects to /upcoming?inviteCode=:code */}
          <Route path="/invite/:code">
            <Invite />
          </Route>
          { /* this /guest-pass/ path exists for legacy purposes — redirects to /upcoming?guestPass=:guestPassId */}
          <Route path="/guest-pass/:guestPassId">
            <GuestPass />
          </Route>
          <AuthedRoute path="/session/:sessionId" component={Session} >
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/profile/">
            <MyFlowClub />
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/goals/">
            <MyGoals />
          </AuthedRoute>
          <AuthedRoute path="/lounge/:loungeId?">
          <Lounge />
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/settings/">
            <Settings />
          </AuthedRoute>
          <AuthedRoute path="/club/decluttering-with-adhd/">
            <Redirect to="/club/decluttering-adhd/" />
          </AuthedRoute>
          <AuthedRoute path="/club/:badgeSlug/hosts/:page?">
            <HostDirectory />
          </AuthedRoute>
          <AuthedRoute path="/club/:badgeSlug">
            <BadgeClub />
          </AuthedRoute>
          <AuthedRoute path="/hosts/:page?">
            <HostDirectory />
          </AuthedRoute>
          <Route path="/clubs/">
            <ClubsDirectory />
          </Route>
          <Route path="/guided-club/:slug">
            <Club />
          </Route>
          <Route path="/host/:hostNameSlug">
            <HostProfile />
          </Route>
          <AuthedRoute path="/host/">
            <HostProfileRedirect />
          </AuthedRoute>
          <Route path="/plans/">
            <Plans />
          </Route>
          <AuthedRoute requireSignedIn path="/trial-extension/">
            <TrialExtension />
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/request-a-theme/">
            <RequestATheme />
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/session-finder/">
            <SessionFinder />
          </AuthedRoute>
          <AuthedRoute requireSignedIn path="/host-onboarding/">
            <HostOnboarding />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/admin-dashboard/">
            <AdminDashboard />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/admin-clubs-overview/">
            <ClubsOverviewAdmin />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/admin-theme-clubs/">
            <ThemeClubsAdmin />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/usage-report/">
            <UsageReport />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/user-badge-stats/">
            <UserBadgeStats />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/slack-messages-list/">
            <SlackMessagesList />
          </AuthedRoute>
          <AuthedRoute requireAdmin path="/referrals-leaderboard/">
            <ReferralsLeaderboard />
          </AuthedRoute>
          <AuthedRoute path="/host-follow-unsubscribe/:hostFollowId">
            <HostFollowUnsubscribe />
          </AuthedRoute>
          <AuthedRoute path="/install-slack/">
            <InstallSlack />
          </AuthedRoute>
          <AuthedRoute path="/" component={({ location }) =>
            <Redirect to={{ ...location, pathname: "/upcoming/", state: { retainModals: true } }} />
          } />
        </Switch>
      </DashboardApp>
    </Route>
  </Switch>
  )
}