/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { FC_DARK_BLUE, FC_LIGHT_BLUE } from '../emotionVariables'

import { marketingURL } from '../../functions/shared/constants'
import { useLocation } from 'react-router';
import { Fragment } from 'react';

const Footer = () => {
  const communityLinks = [
    { link: `/hosts/`, text: 'Meet Our Hosts', target: '_self'},
    { link: 'https://help.flow.club/en/categories/189185-becoming-a-flow-club-host', text: 'Host FAQ', target: '_blank'},
    { link: `${marketingURL}/community-guidelines`, text: 'Community Guidelines', target: '_blank'},
    { link: `${marketingURL}/blog`, text: 'Blog', target: '_blank' },
    { link: `${marketingURL}/wall-of-love`, text: 'Wall of Love', target: '_blank' },
  ]

  const companyLinks = [
    { link: 'https://www.x.com/flowdotclub', text: '@flowdotclub on X (Twitter)', target: '_blank'},
    { link: 'https://www.linkedin.com/company/flow-club/', text: 'Flow Club on LinkedIn', target: '_blank'},
    { link: 'https://flowclub.notion.site/Work-at-Flow-Club-1e6cc84bfc0d4463ab333ee9bc02c46a', text: 'Jobs', target: '_blank'},
  ]

  const resourceLinks = [
    { link: '/plans/', text: 'Pricing', target: '_self'},
    { link: `https://help.flow.club`, text: 'Help Center', target: '_blank' },
    { link: `${marketingURL}/best-work-music`, text: 'Best Work Music', target: '_blank' },
    { link: 'https://www.phonefreehour.com/', text: 'Phone Free Hour', target: '_blank' },
  ]

  const helpLinks = [
    { link: `${marketingURL}/privacy-policy`, text: 'Privacy Policy', target: '_blank' },
    { link: `${marketingURL}/cookie-policy`, text: 'Cookie Policy', target: '_blank'},
    { link: `${marketingURL}/terms-and-conditions`, text: 'Terms and Conditions', target: '_blank'},
  ]

  const currentYear = new Date().getFullYear()

  const location = useLocation()
  const hiddenPaths = ["home-onboarding", "time-onboarding", "intention-onboarding"]
  if (hiddenPaths.some(path => location.pathname.includes(path))) {
    return null
  }

  return (
    <div css={css`
      background-color: ${FC_DARK_BLUE};
      color: #fff;
      font-size: 14px;

      padding: 20px 0px;
      a {
        color: #fff;
        &:hover {
          color: ${FC_LIGHT_BLUE};
        }
     }
    `}>
      <Container>
        <Row>
          <Col md={3} xs={12}>
            <h2 className="FlowClub-Title">
              <a href="https://www.flow.club">Flow Club</a></h2>
          </Col>
          <Col md={3} xs={12}>
            <b>Community</b>
            <br />
            {communityLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
            <br />
          </Col>
          <Col md={3} xs={12}>
            <b>Company</b>
            <br />
            {companyLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
            <br />
            <b>Resources</b>
            <br />
            {resourceLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
            <br />
          </Col>
          <Col md={3} xs={12}>
            <p>Made With ☕️ ❤️ 🎧 🐕 🧘‍<br />
              © Happy Rhinos Corporation {currentYear}</p>
            <a href="mailto:help@flow.club">help@flow.club</a>
            <br />
            {helpLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;