/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState} from 'react';
import { Form } from 'react-bootstrap';

import { UserContext } from '../../UserProvider';

import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FlowButton, LoadingIndicator, Modal, Text, TEXT_STYLES } from '../../components/flowComponents';
import { toastError } from '../../components/utils/toaster';
import { useCreateNewUserInSessionReport } from "../../fetch/endpoints"


export const ReportUserModal = ({ reportedUserId, sessionId, attachmentUrl, onClose = () => {} }) => {
  const { user } = useContext(UserContext)
  const [ reason, setReason ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: reportUser } = useCreateNewUserInSessionReport()

  const handleChange = (event) => {
    setReason(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmitting(true)
    const { success, error } = await reportUser({
      reportedUserId,
      sessionId,
      reason,
    })
    setSubmitting(false)
    sendSegmentEvent("Report User From Tile Submitted", { from: "inSessionTile", reportedUserId, sessionId, reportingUserId: user.uid })
    if (success) {
      setSubmitted(true)
    } else {
      console.error(error)
      toastError({ message: "Error submitting user report. Please try again." })
    }
  }

return (
  <Modal width={500} onClose={onClose}>
    <div css={css`
      padding: 32px 24px 12px 24px;
    `}>
      <Text style={TEXT_STYLES.APP_H3} customCss={css`margin-bottom: 24px;`}>
        Report Member
      </Text>
      {submitted ?
        <div>
          <Text style={TEXT_STYLES.BODY}>Thank you for reporting this member. Our team will review it shortly.</Text>
          <FlowButton customCss={css`margin-top: 24px;`} onClick={onClose}>Close</FlowButton>
        </div>
      :
      <div>
      <Text style={TEXT_STYLES.BODY}>Please explain how this member violated the <a href="https://www.flow.club/community-guidelines" target="_blank" rel="noopener noreferrer">community guidelines.</a></Text>

      {submitting ? <Text style={TEXT_STYLES.BODY} customCss={css`margin-top: 16px;`}><LoadingIndicator /> Submitting...</Text> :
      <form onSubmit={handleSubmit}>
      <Form.Control
        css={css`
          height: 48px;
          background: rgba(196, 196, 196, 0.24);
          border-radius: 6px;
          resize: none;
          border: none;
          font-size: 14px;
          padding: 14px 12px 13px;
          margin-top: 16px;

          &::placeholder {
            color:rgba(0, 0, 0, 0.6);
          }
        `}
        id="chatInput"
        as="textarea"
        rows={2}
        name="reason"
        value={reason}
        onChange={handleChange}
        placeholder="Provide your reason here..."
      />
      <FlowButton type="submit" customCss={css`margin-top: 24px;`}>Submit</FlowButton>
      </form>}
      </div>}
    </div>
  </Modal>
)
}

