/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { functionsHost } from './firebase';
import { FC_LIGHTER_BLUE } from './emotionVariables';
import FlowClubSlackImage from './images/flowclub_slack.png'

export const InstallSlack = ({ }) => {

  return (
    <div css={css`
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: ${FC_LIGHTER_BLUE};
      min-height: 100vh;
    `}>
      <div css={css`
        max-width: 650px;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        padding: 64px 32px;
      `}>
        <h2>Flow Club, now on Slack!</h2>
        <img
          css={css`
            width: 285px;

            margin-top: 68px;
            margin-bottom: 60px;
          `}
          src={FlowClubSlackImage}
          alt="Flow Club on Slack" />
        <p css={css`margin-bottom: 33px;`}>
          Install Flow Club to your community or company Slack workspace.
        We'll automatically post updates and reminders for your group's sessions so everyone has a chance to flow together.
        </p>
        <a href={`${functionsHost}installSlack`}>
          <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
        </a>
      </div>
    </div>
  )
}