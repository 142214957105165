/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

import {
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import { db } from '../firebase';
import { HostBadge, HostBadgePill } from '../components/hosts/host-badge';
import { CommunityOverlay, InviteLink, InviteModalBody } from '../InviteUtils';
import { getSessionShareLink } from './SessionUtils';
import { sortBy } from '../../functions/shared/helpers';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useGetDisplayName } from '../UserProvider';

export const CoworkerList = ({ event, user, inviteTag }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const [showSessionInvite, setShowSessionInvite] = useState(false);
  const [guests, setGuests] = useState({})
  // TODO (2021-02-19, David): Run this query ONCE ON the schedule page by lifting state of
  // users' tags and all attendees on the page UP 💪🏼🆙
  useEffect(() => {
    if (event && event.guests && event.guests.length > 0) {
      // Unfortunately we cannot combine this `in` with an `array-contains-any` for the
      // tags 🙃
      const currUserBadges = [...(user && user.tags ? new Set(user.tags) : new Set([inviteTag]))]
      db.collection('users')
        .where(firebase.firestore.FieldPath.documentId(), 'in', event.guests)
        .get()
        .then((querySnapshot) => {
          // TODO (2021-02-19, David): We don't want to keep doing this
          // this way, for obvious reasons 🙃
          let tempGuests = {}
          querySnapshot.forEach((userDoc) => {
            const userData = userDoc.data();
            tempGuests[userDoc.id] = {
              id: userDoc.id,
              displayName: userData.displayName,
              emojis: userData.emojis,
              company: userData.company,
              hostStatus: userData.hostStatus,
              sessions: userData.sessions,
              preferences: userData.preferences,
              isHostInResidence: userData.isHostInResidence
            }
          });
          setGuests(tempGuests)
        })
        .catch((error) => {
          console.log("Error getting users: ", error);
        });
    }
  }, [event, user, inviteTag]);
  if (!event.guests) {
    return "";
  }
  const hasInvites = user !== null && user.inviteCode !== undefined
  const userInEvent = Boolean(user) && Boolean(event.guests) && new Set(event.guests).has(user.uid)
  const now = Date.now();
  const gracePeriod = 1000 * 60 * 5;
  const startTime = event.start.getTime();
  return (
    <>
      <Row>
        {/* sort so that the host always appears first */}
        {sortBy(
          Object.entries(guests),
          ([guestId]) => guestId === event.hostId,
          true
        ).map(([guestId, guest]) => (
          <Col xs="4" md="3" className="coworkers" key={guestId}>
            <Coworker
              isHosting={guestId === event.hostId}
              activeUserIsHosting={user !== null && user.uid === event.hostId}
              showCompany={event.showCompany}
              guest={guest}
            />
          </Col>
        ))}
        {!hasInvites &&
          <Col xs="4" md="3" className="coworkers" key="invite">
            <InviteLink />
          </Col>
        }
        {hasInvites && userInEvent && startTime + gracePeriod > now &&
          <Col xs="4" md="3" className="coworkers">
            <a href={getSessionShareLink(event.id, user.inviteCode)} onClick={
              (e) => {
                sendSegmentEvent("Invite a friend clicked")
                e.preventDefault();
                setShowSessionInvite(true);
              }
            }>
              Invite a friend
            </a>
          </Col>
        }
      </Row>
      <Modal
        show={showSessionInvite}
        onHide={() => setShowSessionInvite(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Want to bring a friend to the event?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb=0">
            Studies show that group flow is more enjoyable than solitary flow.
          </p>
          {user && <p>
            <InviteModalBody
              user={user}
              shareButtonText="Share Invite" shareLink={getSessionShareLink(event.id, user.inviteCode)}
              showInviteActionDefault={true} />
          </p>}
        </Modal.Body>
      </Modal>
    </>
  );
}

const Coworker = ({ isHosting, activeUserIsHosting, showCompany, guest }) => {
  const { company, sessions } = guest
  const guestDisplayName = useGetDisplayName(guest)
  const badges = guest.badges || []

  return (
    <div css={css`display: flex;`}>
      <div>
        {isHosting ?
          <><HostBadgePill /> {guestDisplayName}</> :
          <><HostBadge user={guest} />{guestDisplayName}</>
        }
        {!isHosting && showCompany && company &&
          <span> ({company})</span>
        }
        {badges.length > 0 &&
          <CommunityOverlay>
            <div className="badge-list">
              {badges.map((badge, index) => (
                <span>
                  {index !== 0 ? ", " : ""}<span className={`badge-${badge}`} key={badge}>{badge}</span></span>
              ))}
            </div>
          </CommunityOverlay>
        }
        {sessions === 0 &&
          <div className="badge-list">
            <span>First Flow</span>
          </div>
        }
      </div>
    </div>
  )
}