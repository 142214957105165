/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BUTTON_STYLES, DarkText, FlowButton, SIZE_PRESETS, TextInput, TEXT_INPUT_TYPES } from '../components/flowComponents';
import { EntryPage, LinkToSignUp, PasswordResetLink } from './EntryPage';
import { FIREBASE_AUTH_METHODS, useLogin } from '../auth/useLogin';
import { useQuery } from '../utils';
import { useState } from 'react';
import firebase from 'firebase/app';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { BANNER_STYLES } from '../components/banners';
import { useHistory } from 'react-router';
import { EmailLoginButton, GoogleLoginButton } from '../auth/loginButtons';

const LOGIN_STATES = {
  SELECTING_PROVIDER: 'selecting_provider',
  ENTERING_EMAIL_PW: 'entering_email_pw',
  ACCOUNT_FOR_EMAIL_ALREADY_EXISTS: 'account_for_email_already_exists'
}

export const Login = ({ onSuccess, onFailure }) => {
  const query = useQuery();
  const redirectAfterLogin = query.get('redirect') || '/upcoming'

  const history = useHistory()
  onSuccess = () => {
    history.push(redirectAfterLogin)
  }

  const [loginState, setLoginState] = useState({ state: LOGIN_STATES.SELECTING_PROVIDER, props: {} })
  const [error, setError] = useState(null)

  const bannerContent = error !== null ? error : (query.get('redirect') !== null ? 'Log in to continue' : null)
  const bannerStyle = error !== null ? BANNER_STYLES.error : BANNER_STYLES.info

  const bottomContent = (
    <div css={css`margin-top: 40px;`}>
      {loginState.state === LOGIN_STATES.ENTERING_EMAIL_PW && 
      <div css={css`margin-bottom: 24px;`}>
        <PasswordResetLink redirectAfterLogin={redirectAfterLogin} />
      </div>}
      <LinkToSignUp/>
    </div>
  )

  let MainContent = null
  switch (loginState.state) {
    case LOGIN_STATES.SELECTING_PROVIDER:
      MainContent = LoginMethodChoices
      break
    case LOGIN_STATES.ENTERING_EMAIL_PW:
      MainContent = EnterEmail
      break
    case LOGIN_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS:
      MainContent = AccountForEmailAlreadyExists
      break
  }

  return (
    <EntryPage bottomContent={bottomContent} bannerContent={bannerContent} bannerStyle={bannerStyle}>
      <h2 css={css`margin-bottom: 32px;`}>Log In</h2>
      <MainContent onSuccess={onSuccess} setLoginState={(newState) => { setLoginState(newState); setError(null); }} setError={setError} {...loginState.props} />
    </EntryPage>
  )
}

const LoginMethodChoices = ({ onSuccess, setLoginState, setError }) => {
  const loginWithGoogle = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE, onSuccess, onFailure: setError })

  return (
    <>
      <GoogleLoginButton customCss={css`margin-bottom: 12px;`} onClick={loginWithGoogle} />
      <EmailLoginButton onClick={() => setLoginState({ state: LOGIN_STATES.ENTERING_EMAIL_PW, props: {} })} onSuccess={onSuccess} />
    </>
  )
}

const EnterEmail = ({ onSuccess, setLoginState, setError }) => {
  const authWithEmailPassword = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.EMAIL_PASSWORD, onSuccess, onFailure: setError })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  
  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      const existingAccountsForEmail = await firebase.auth().fetchSignInMethodsForEmail(email)
      if (existingAccountsForEmail.includes('google.com')) {
        setLoginState({ state: LOGIN_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS, props: { email } })
      } else {
        authWithEmailPassword(email, password)
        setLoading(false)
      }
    } catch (error) {
      setError(translateAuthErrorCodeToMessage(error.code))
      setLoading(false)
    }
  }

  const disabled = !(email.length > 0 && password.length > 0)
  
  return (
    <div>
      <form onSubmit={onSubmit}>
        <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} value={email} onChange={(event) => setEmail(event.target.value)} placeholder={'mcsikszentmihalyi@gmail.com'} label={'Email'}/>
        <TextInput sizePreset={SIZE_PRESETS.CHUNKY} value={password} onChange={(event) => setPassword(event.target.value)} placeholder={'Enter your password'} label={'Password'} type={TEXT_INPUT_TYPES.PASSWORD} />

        <div css={css`
          display: flex;
          margin-top: 16px;
        `}>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} customCss={css`flex: 1; margin-right: 4px;`} onClick={() => setLoginState({ state: LOGIN_STATES.SELECTING_PROVIDER, props: {} })}>Cancel</FlowButton>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} disabled={disabled} loading={loading} customCss={css`flex: 1;`} onClick={onSubmit} type={'submit'}>
            Log In
          </FlowButton>
        </div>
      </form>
    </div>
  )
}

const AccountForEmailAlreadyExists = ({ onSuccess, setError, email }) => {
  const loginWithGoogle = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE, onSuccess, onFailure: setError })
  return (
    <>
      <DarkText>You already have an account associated with:</DarkText>
      <DarkText customCss={css`font-weight: 700; margin-bottom: 20px;`}>{email}</DarkText>
      <DarkText customCss={css`margin-bottom: 28px;`}>Log in with Google to continue.</DarkText>
      <div css={css`
        display: flex;
      `}>
        <GoogleLoginButton customCss={css`flex: 1;`} onClick={loginWithGoogle} />
      </div>
    </>
  )
}