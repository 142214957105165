/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_DARK_BLUE, FC_LIGHTER_BLUE, FC_IRIS_BLUE } from '../emotionVariables';

export const YetAnotherBanner = ({ children }) => {
  return (
    <div css={css`
      padding: 24px;
      margin-bottom: 12px;
      background-color: #EAF3FF;
      border-radius: 8px;
      color: #091454;
      text-align: center;
      letter-spacing: 0.5px;
    `}>
      {children}
    </div>
  )
}

export const BANNER_STYLES = {
  info: 'info',
  error: 'error'
}
// there's just no useful way to distinguish these 🤷‍♂️
export const YetYeetAnotherBanner = ({ children, bannerStyle = BANNER_STYLES.info }) => {
  const bannerStyles = {
    [BANNER_STYLES.info]: css`
      background-color: ${FC_LIGHTER_BLUE};
      color: ${FC_DARK_BLUE};
    `,
    [BANNER_STYLES.error]: css`
      color: #A55528;
      background-color: #FFEEE4;
    `
  }
  return (
    <div css={css`
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;

      letter-spacing: 0.5px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      position: fixed;
      top: 0;
      width: 100%;

      ${bannerStyles[bannerStyle]}
    `}>
      {children}
    </div>
  )
}

export const DescriptivelyNamedPurpleBanner = ({ children }) => {
  return (
    <div css={css`
      background: ${FC_IRIS_BLUE};
      color: white;
      text-align: center;

      width: 100%;
      padding: 8px 0;

      a {
        color: white;
        text-decoration: underline;
        &:hover {
          color: #ffffffb0;
        }
      }
    `}>
      {children}
    </div>
  )
}