/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUserIsUnauthed } from '../../functions/shared/helpers';
import { FC_IRIS_BLUE } from '../emotionVariables';
import { useSetUserPreferences, useGetBadgesForUsers, useSetJoinedBadges, useGetUserAggregateData, } from '../fetch/endpoints';
import { useAutomaticallyFetch } from '../fetch/helpers';
import { Banner } from '../shared/components/banner';
import { usePreferences, UserContext } from '../UserProvider';
import { FlowButton, LoadingIndicator, Modal, Text, TEXT_STYLES } from './flowComponents';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { toastError, toastSuccess } from './utils/toaster';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { BadgesSelector } from './BadgesSelector';

const SuccessMessage = ({ onClose }) => {
  return (
    <div>
      <Text style={TEXT_STYLES.BODY}>You can join or leave clubs<Link to="/settings/clubs-and-hosts/"> from your clubs and hosts page</Link>.</Text>
      <FlowButton customCss={css`margin-top: 24px;`} onClick={onClose}>Close</FlowButton>
    </div>
  )
}

const SubmittingMessage = () => {
  return (
    <Text style={TEXT_STYLES.BODY} customCss={css`margin-top: 16px;`}>
      <LoadingIndicator /> Updating your preferences...
    </Text>
  )
}

const CLUBS_TO_PRESELECT = 3

const ThemeClubsModal = ({ userEventTypes = [], onClose = () => {} }) => {
  const { user } = useContext(UserContext)
  const [selectedBadgeIds, setSelectedBadgeIds] = useState(new Set())
  const { performFetch: setJoinedBadges, fetching: settingJoinedBadges } = useSetJoinedBadges()
  const { performFetch: setUserPreferences } = useSetUserPreferences()

  const [ submitting, setSubmitting ] = useState(false)
  const [ submitted, setSubmitted ] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const selectedUserEventTypes = userEventTypes.slice(0, CLUBS_TO_PRESELECT)
  const { result: badgeData } = useAutomaticallyFetch(useGetBadgesForUsers, { userIds: [] }, {})
  const themeBadges = badgeData === null ? [] : badgeData.badges.filter(badge => badge.theme)

  useEffect(() => {
    if (badgeData !== null) {
      const membershipBadgeIds = badgeData.badgeMembershipsByUser[user.uid]?.map(membership => membership.badgeId) || [];
    
      const eventTypeBadgeIds = selectedUserEventTypes.length > 0
        ? selectedUserEventTypes
            .map(({theme}) => badgeData.badges.find(badge => badge.theme === theme)?.id)
            .filter(id => id !== undefined)
        : [];
    
      setSelectedBadgeIds(new Set([...membershipBadgeIds, ...eventTypeBadgeIds]));
    }
  }, [badgeData])

  const updateBadges = async () => {
    setSubmitting(true)
    sendSegmentEvent("Badges Updated from Theme Badge Modal", { numClubs: selectedBadgeIds.size })
    const { success } = await setJoinedBadges({ badgeIds: Array.from(selectedBadgeIds) })
    setSubmitting(false)
    if (success) {
      setSubmitted(true)
      await setUserPreferences({ newPreferenceData: { setOrDismissedThemeClubsBanner: true } })
      toastSuccess({ message: 'Successfully saved your changes!' })
    } else {
      toastError({ message: "There was an error saving your clubs. Please try again" })
    }
  }

  return (
    <Modal width={500} onClose={onClose} dismissOnClickOutside={false}>
      <div css={css`
        padding: 32px 24px 12px 24px;
      `}>
        <Text style={TEXT_STYLES.APP_H5} customCss={css`margin-bottom: 24px;`}>
          Introducing new clubs that support how you work
        </Text>
        {submitted ?
          <SuccessMessage onClose={onClose} />
        :
        <div>
        {submitting ?
          <SubmittingMessage />
        :
        <div>
          <Text>By converting themes to clubs, you can more easily find members who share your intentions and hosts can better facilitate knowing everyone in attendance have the same goal in mind.</Text>
          {userEventTypes.length !== 0 &&
          <div css={css`
            margin: 8px 0px;
            border-top: 1px solid #ccc;
            padding-top: 8px;
            `}>
            <Text customCss={css`font-style: italic;`}>We've preselected new clubs we think you'd like based on your history this year:</Text>
            <ul css={css`margin: 8px 0px;`}>
            {selectedUserEventTypes.map(({theme, sessions}) => (
              <li key={theme}><b>{theme}</b>: You've been to {sessions} sessions</li>
            ))}
            </ul>
          </div>}
          {badgeData !== null ?
            <BadgesSelector badges={themeBadges} selectedBadgeIds={selectedBadgeIds} setSelectedBadgeIds={setSelectedBadgeIds} showHeader={false} /> :
            <div css={css`display: flex; justify-content: center;`}><LoadingIndicator /></div>
          }
          <FlowButton onClick={updateBadges} loading={settingJoinedBadges} fillAvailableWidth>Join clubs</FlowButton>
        </div>}
        </div>}
      </div>
    </Modal>
  )
}

export const ThemeClubsBanner = () => {
  const { user } = useContext(UserContext)
  const location = useLocation()
  // client-side optimistic state
  const [ closed, setClosed ] = useState(false)
  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: setUserPreferences } = useSetUserPreferences()

  const { result: aggregateData } = useAutomaticallyFetch(useGetUserAggregateData)
  const userEventTypes = aggregateData?.userEventTypes || []
  const sortedUserEventTypes = (
    Object.entries(userEventTypes)
    .filter(([_, sessions]) => sessions > 0)
    .map(([theme, sessions]) => ({ theme, sessions }))
    .sort((a, b) => b.sessions - a.sessions)
  )

  const openModal = () => {
    sendSegmentEvent("Opened theme clubs modal")
    setActiveModal(ThemeClubsModal, {
      userEventTypes: sortedUserEventTypes,
      onClose: () => {
        setActiveModal(null)
      }
    })
  }

  const dismiss = () => {
    setUserPreferences({ newPreferenceData: { setOrDismissedThemeClubsBanner: true } })
    setClosed(true)
  }

  const shouldShowBanner = useShouldShowBanner(closed)
  if (!shouldShowBanner) {
    return null
  }

  if (getUserIsUnauthed(user)) {
    return null
  }

  const hiddenPaths = ["settings/profile", "intention-onboarding"]
  if (hiddenPaths.some(path => location.pathname.includes(path))) {
    return null
  }

  return (
    <Banner onClose={dismiss}>
      <div css={css`display: flex; gap: 12px; align-items: center;`}>
        <div css={css`
          padding: 4px 8px;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
          width: 42px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
          <Text style={TEXT_STYLES.OVERLINE} customCss={css`color: ${FC_IRIS_BLUE};`}>NEW</Text>
        </div>
        <Text>Upgrading themes to clubs to support more intention </Text>
        <FlowButton onClick={openModal}>See details</FlowButton>
      </div>
    </Banner>
  )
}

const useShouldShowBanner = (locallyClosed) => {
  const { user } = useContext(UserContext)
  const { setOrDismissedThemeClubsBanner } = usePreferences()
  return user !== null && setOrDismissedThemeClubsBanner === undefined && !locallyClosed
}