/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { CalendarBooking } from './CalendarBooking/CalendarBooking';
import { ConnectCalendarButton } from './CalendarBooking/ConnectCalendarButton';
import { Text, TEXT_STYLES } from './components/flowComponents';
import { UserContext } from './UserProvider';

export const SessionFinder = () => {

  const { user } = useContext(UserContext)
  const userHasRefreshToken = user !== null && user.googleCalendarAuth !== undefined
  const showConnectCalendarPrompt = true || !userHasRefreshToken

  return (
    <div css={css`padding: 32px 12px; display: flex; flex-direction: column; align-items: center; text-align: center;`}>
      <Text style={TEXT_STYLES.APP_H4} customCss={css`margin-bottom: 16px;`}>Let's block out time for getting work done 💃🎉</Text>
      <Text style={TEXT_STYLES.BODY_2} customCss={css`margin-bottom: 36px;`}>Select a span of time, and we'll help you find a Flow Club session.</Text>
      {showConnectCalendarPrompt && <ConnectCalendarButton />}
      <CalendarBooking />
    </div>
  )
}