import dayjs from 'dayjs';
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Badge,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'

import {
  AudioOff,
  AudioOn,
  VideoOff,
} from './TileIcons'
import './Tile.scss'

import { getUser } from '../../../event-utils'
import { CommunityOverlay } from '../../../InviteUtils'
import EventContext from '../../../EventContext'
import { UserContext, useGetDisplayName } from '../../../UserProvider'
import Icon, {TYPE_CAMERA_OFF, TYPE_LINKEDIN_CIRCLE, TYPE_MIC_OFF, TYPE_STAR, TYPE_TWITTER_CIRCLE, TYPE_FLAG, TYPE_SHOW, TYPE_HIDE } from '../Icon/Icon'
import { HostBadgePill, HostBadge } from '../../../components/hosts/host-badge'
import { LightGrayText, Text, TEXT_STYLES } from '../../../components/flowComponents';
import { FC_LIGHT_BLUE, FC_PINK, FC_RED } from '../../../emotionVariables';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { ONE_SECOND } from '../../../../functions/shared/constants';
import { useModal } from '../../../wrappers/MagnificentlyMunificentModalManager';
import { ReportUserModal } from '../ReportUserModal';


function getTrackUnavailableMessage(kind, trackState) {
  if (!trackState) return;
  switch (trackState.state) {
    case 'blocked':
      if (trackState.blocked.byPermissions) {
        return `It looks like you've blocked access to your microphone and camera. Check your browser settings, then reload the page.`;
      } else if (trackState.blocked.byDeviceMissing) {
        return `We couldn't load your ${kind}. Please allow access, then reload the page.`;
      }
      return `Sorry, looks like your ${kind} is blocked`;
    case 'off':
      if (trackState.off.byUser) {
        if (kind === 'audio') {
          return AudioOff;
        } else if (kind === 'video') {
          return VideoOff;
        }
        return `${kind} muted`;
      } else if (trackState.off.byBandwidth) {
        return `${kind} off because of connectivity issues`;
      }
      return `${kind} off`;
    case 'sendable':
      return `${kind} not subscribed`;
    case 'loading':
      return `${kind} loading...`;
    case 'interrupted':
      return `${kind} interrupted`;
    case 'playable':
      return null;
    default:
      return null;
  }
}

const VideoControlTooltip = (title) => <Tooltip className="in-session-tooltip">{title}</Tooltip>

/**
 * Props
 * - videoTrackState: DailyTrackState?
 * - audioTrackState: DailyTrackState?
 * - isLocalPerson: boolean
 * - isLarge: boolean
 * - disableCornerMessage: boolean
 * - onClick: Function
 */

const BottomRightButtons = ({ onFlag, userVideoIsHidden, onToggleUserVideo }) => {
  const positionCss = css`
    position: absolute;
    bottom: 6px;
    right: 6px;
    z-index: 6;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0px;
    margin: 0px;
  `
  return (
    <div css={positionCss}>
      <ToggleUserVideoButton userVideoIsHidden={userVideoIsHidden} onClick={onToggleUserVideo} />
      <FlagButton onClick={onFlag} />
    </div>
  )
}

const bottomRightButtonCss = css`
  cursor: pointer;
  user-select: none;
  background: rgba(255, 255, 255, 0.87);
  width: 24px;
  height: 24px;
  border-radius: 8px;
`

const FlagButton = ({ onClick }) => {
  const buttonCss = css`
   ${bottomRightButtonCss};
   padding: 3px;
    --icon-fill-color: ${FC_RED};
    &:hover, &:active {
      --icon-fill-color: ${FC_PINK};
    }
  `

  const iconCss = css`
    width: 18px;
    height: 18px;
    fill: var(--icon-fill-color);
  `
  return (
    <div>
       <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 500 }}
          overlay={VideoControlTooltip("Flag User")}
        >
        <div onClick={onClick} css={buttonCss}>
          <Icon type={TYPE_FLAG} css={iconCss} />
        </div>
      </OverlayTrigger>
    </div>
  )
}

const ToggleUserVideoButton = ({ userVideoIsHidden, onClick }) => {
  const buttonCss = css`
   ${bottomRightButtonCss};
   padding: 0px;
    --icon-fill-color: ${FC_RED};
    &:hover, &:active {
      --icon-fill-color: ${FC_PINK};
    }
  `

  const iconCss = css`
    width: 24px;
    height: 24px;
    stroke: var(--icon-fill-color);
  `
  return (
    <div>
       <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 500 }}
          overlay={VideoControlTooltip(userVideoIsHidden ? "Turn user's video back on" : "Hide user's video for this session")}
        >
        <div onClick={onClick} css={buttonCss}>
          <Icon type={userVideoIsHidden ? TYPE_HIDE : TYPE_SHOW} css={iconCss} />
        </div>
      </OverlayTrigger>
    </div>
  )
}


export default function Tile(props) {
  const sendSegmentEvent = useSendSegmentEvent()
  const { event, participants, callObject, hiddenUserVideos, setHiddenUserVideos } = useContext(EventContext);
  const { user: loggedInUser } = useContext(UserContext)
  const hostId = event ? event.hostId : null;
  const userIsHost = loggedInUser && hostId ? loggedInUser.uid === hostId : false
  const [user, setUser] = useState(null);
  const userId = user ? user.uid : null;
  const tileIsLoggedInUser = userId && loggedInUser ? userId === loggedInUser.uid : false
  const tileIsHost = userId && hostId ? userId === hostId : false
  const [showOverlay, setShowOverlay] = useState(false);
  const videoTile = useRef(null);
  const videoEl = useRef(null);
  const audioEl = useRef(null);
  const loggedInUserTags = new Set(loggedInUser.tags)
  const sharedTags = user && user.tags && user.tags.filter(x => loggedInUserTags && loggedInUserTags.has(x))
  const firstTimer = user && user.sessions === 0
  const userDisplayName = useGetDisplayName(user)
  const { setActiveModal } = useModal()
  const userVideoIsHidden = useMemo(() => hiddenUserVideos.includes(userId), [hiddenUserVideos, userId])

  const flagUser = useCallback(() => {
    sendSegmentEvent("Opened Report User From Tile Modal", { userId, sessionId: event.id })
    setActiveModal(ReportUserModal, { reportedUserId: userId, sessionId: event.id, onClose: () => { setActiveModal(null)} })
  }, [userId])

  const toggleUserVideoHidden = useCallback(() => {
    if (hiddenUserVideos.includes(userId)) {
      setHiddenUserVideos(hiddenUserVideos.filter(id => id !== userId))
      sendSegmentEvent("User Video Turned Back On", { userId, sessionId: event?.id })
    } else {
      setHiddenUserVideos([...hiddenUserVideos, userId])
      sendSegmentEvent("User Video Turned Off", { userId, sessionId: event?.id })
    }
  }, [hiddenUserVideos, userId])

  const videoTrack = useMemo(() => {
    return props.videoTrackState && props.videoTrackState.state === 'playable'
      ? props.videoTrackState.track
      : null;
  }, [props.videoTrackState]);

  const audioTrack = useMemo(() => {
    return props.audioTrackState && props.audioTrackState.state === 'playable'
      ? props.audioTrackState.track
      : null;
  }, [props.audioTrackState]);

  const videoUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage('video', props.videoTrackState);
  }, [props.videoTrackState]);

  const audioUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage('audio', props.audioTrackState);
  }, [props.audioTrackState]);

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    videoEl.current &&
      (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [videoTrack, props.hidingSelfView, userVideoIsHidden]);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    audioEl.current &&
      (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [audioTrack]);

  useEffect(() => {
    if (props.userId) {
      getUser(props.userId)
      .then((user) => setUser(user))
      .catch((err) => {
        setUser(null);
      })
    }
  }, [props.userId])

  const playingVideo = !props.isScreenShare && videoTrack

  function getVideoComponent() {
    return (
      playingVideo && <video autoPlay muted playsInline ref={videoEl} />
    );
  }

  function getAudioComponent() {
    return (
      !props.isLocalPerson &&
      audioTrack && <audio autoPlay playsInline ref={audioEl} />
    );
  }

  const linkedinIconLinkClicked = () => {
    sendSegmentEvent("Linkedin Icon Link Clicked", { linkedin: user.linkedin, from: "videoTile" })
  }

  const twitterIconLinkClicked = () => {
    sendSegmentEvent("Twitter Icon Link Clicked", { twitter: user.twitter, from: "videoTile" })
  }

  const muteUser = useCallback(() => {
    const sessionId = props.sessionId
      if (!callObject || !sessionId) return
      let updateList = {}
      updateList[sessionId] = {setAudio: false}
      callObject.updateParticipants(updateList)
  }, [callObject, props.sessionId])

  const getHoverOverlay = useCallback(() => {
    // Show Tooltip overlay with user info
    if (!showOverlay && videoTrack !== null) {
      return "";
    }

    return (
      <div className="user-overlay" onMouseLeave={() => setShowOverlay(false)}>
        <div css={css`display: flex; flex-wrap: wrap; gap: 4px; align-items: center;`}>
          <span><HostBadge user={user} />{user ? userDisplayName: "Flow Club Member"}</span>
          {user && user.pronunciation && <><small className="small mr-2">({user.pronunciation})</small></>}
          {user && user.pronouns && <><small className="small tile-details">{user.pronouns}</small><br /></>}
          {user && event && event.showCompany && user.company ? <><small className="small">{user.company}</small><br /></> : ""}
          {user && user.location && <><small className="small tile-details">{user.location}</small></>}
          {user && user.timezone && <><small className="small tile-details">{dayjs().tz(user.timezone).format('h:mmA')}</small></>}
          {firstTimer ? <Badge variant="success">First Session</Badge> : ""}
          {sharedTags ?
            <CommunityOverlay>
              <div className="badge-list">
                {sharedTags.map((badge, index) => (
                  <span key={index}>
                    {index !== 0 ? ", " : ""}<span className={`badge-${badge}`}>{badge}</span>
                  </span>
                ))}
              </div>
            </CommunityOverlay>
            : ""}
          {user && (user.linkedin !== "" && user.linkedin !== undefined) &&
            <a onClick={linkedinIconLinkClicked} href={user.linkedin} target="_blank" rel="noreferrer">
              <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_LINKEDIN_CIRCLE} />
            </a>
          }
          {user && (user.twitter !== "" && user.twitter !== undefined) &&
            <a onClick={twitterIconLinkClicked} href={user.twitter} target="_blank" rel="noreferrer">
              <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_TWITTER_CIRCLE} />
            </a>
          }
        </div>
        {user && user.uid && participants && participants[user.uid] &&
          <UserTaskList goals={participants[user.uid].goals} />
        }
        {userIsHost && audioTrack ?
          <div className="corner right-corner">
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 500 }}
              overlay={VideoControlTooltip("Mute")}
            >
              <Button className="mute-btn" variant="none" onClick={muteUser}>
                <AudioOn />
              </Button>
            </OverlayTrigger>
          </div>
          : ""
        }
        {!tileIsLoggedInUser && showOverlay && <BottomRightButtons onFlag={flagUser} userVideoIsHidden={userVideoIsHidden} onToggleUserVideo={toggleUserVideoHidden} />}
      </div>
    );
  }, [user, participants, showOverlay, videoTrack, audioTrack, muteUser, userIsHost, sharedTags])

  function getOverlayComponent() {
    // Show overlay when video is unavailable. Audio may be unavailable too.
    return (
      videoUnavailableMessage && (
        <div className="overlay">
          <p className="d-flex justify-content-center overlay-icons">
          <Icon type={TYPE_CAMERA_OFF} error={true} />
          {audioUnavailableMessage && <Icon type={TYPE_MIC_OFF} error={true} />}
          </p>
          {videoUnavailableMessage}
        </div>
      )
    );
  }



  const getLeftCornerMessageComponent = useCallback(() => {
    return (
      <div className="corner left-corner" css={css`display: flex;`}>
        {tileIsHost &&
          <p >
            <HostBadgePill />
          </p>
        }
      </div>
    );
  }, [tileIsHost, user])

  function getRightCornerMessageComponent() {
    return (
      !props.disableCornerMessage &&
      audioUnavailableMessage && (
        <p className="corner right-corner">
          <AudioOff />
        </p>
      )
    );
  }

  function getClassNames() {
    let classNames = 'tile';
    classNames += ' large'; //props.isLarge ? ' large' : ' small';
    props.isLocalPerson && (classNames += ' local');
    props.isJoinScreen && (classNames += ' join-screen');
    props.isActiveSpeaker && (classNames += ' active');
    return classNames;
  }
  if (props.isLocalScreenShare) {
    return <span></span>;
  }
  if (props.isScreenShare) {
    return <span>{getAudioComponent()}</span>
  }

  const onMouseEnter = () => {
    sendSegmentEvent("Video Tile Moused Over", {
      isOwnTile: props.isLocalPerson,
      videoAvailable: playingVideo !== null,
    })
    setShowOverlay(true)
  }

  const onMouseOver = () => { setShowOverlay(true) }

  const onMouseLeave = () => { setShowOverlay(false) }

  const getUserIsPhoneFree = () => {
    return user && user.uid && participants && participants[user.uid] && participants[user.uid].phoneFreeLastActive && participants[user.uid].phoneFreeLastActive.toMillis() >= Date.now() - (ONE_SECOND * 35)
  }

  const twinkling = keyframes`
    0%, 100% { box-shadow: 0 0 10px rgba(255, 255, 128, 0.7), 0 -10px 10px rgba(255, 255, 128, 0.7); }
    25% { box-shadow: 0 0 20px rgba(255, 255, 128, 0.5), 0 -20px 20px rgba(255, 255, 128, 0.5); }
    50% { box-shadow: 0 0 30px rgba(255, 255, 128, 0.3), 0 -30px 30px rgba(255, 255, 128, 0.3); }
    75% { box-shadow: 0 0 15px rgba(255, 255, 128, 0.5), 0 -15px 15px rgba(255, 255, 128, 0.5); }
  `;

  return (
    <div className={getClassNames()} onClick={props.onClick} css={css`
      ${getUserIsPhoneFree() && css`animation: ${twinkling} 5s infinite;`}
      border-radius: 8px;
    `}>
      {props.showOverlay ?
        <div css={css`isolation: isolate;`} ref={videoTile} onMouseEnter={onMouseEnter} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
          {getHoverOverlay()}
          <div className="background" />
            {getLeftCornerMessageComponent()}
            {getOverlayComponent()}
            {
              !props.hidingSelfView ?
                userVideoIsHidden ?
                <LightGrayText customCss={css`
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  width: 100%;
                  height: 100%;
                  font-weight: bold;
                  z-index: 2;
                  font-size: 18px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-style: oblique;
                  text-align: center;
                `}>
                  <div>
                    You turned this user's video off for the rest of this session.
                  </div>
                </LightGrayText>
                :
                getVideoComponent() :
                <LightGrayText customCss={css`
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  width: 100%;
                  height: 100%;
                  font-weight: bold;
                  z-index: 2;
                  font-size: 18px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-style: oblique;
                  text-align: center;
                `}>
                  <div>
                    {playingVideo ? 'Self view is off, but your video is still visible to others' : 'Self view is off. Your camera is turned off.'}
                  </div>
                </LightGrayText>
            }
            {getAudioComponent()}
            {getRightCornerMessageComponent()}
        </div>
        :
        <div ref={videoTile}>
          <div className="background" />
          {getLeftCornerMessageComponent()}
          {getOverlayComponent()}
          {getVideoComponent()}
          {getAudioComponent()}
          {getRightCornerMessageComponent()}
        </div>
      }
    </div>
  );
}

const UserTaskList = ({ goals }) => {
  return (
    <>
      <div css={css`
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        margin-top: 12px;
        margin-bottom: 8px;
      `}>
        TASKS
      </div>
      {goals.filter(goal => goal.text.length > 0).map((goal, index) => <UserTask key={index}>{goal}</UserTask>)}
    </>
  )
}

const UserTask = ({ children: goal }) => {
  const { text, completedAt } = goal
  return (
    <div css={css`
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-overflow: ellipsis;
      overflow: hidden;
    `}>
      {completedAt !== null ? '☑' : '☐'} {text}
    </div>
  )
}