import { useInputSettings, useLocalParticipant, useVideoTrack } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { useSendSegmentEvent } from "../wrappers/SegmentProvider";

const BACKGROUND_BLUR_KEY = "backgroundBlurOn";
const BACKGROUND_IMAGE_KEY = "backgroundImageOn";
const BACKGROUND_BLUR_STRENGTH = 1.0; // Number between 0 and 1: https://docs.daily.co/reference/daily-js/instance-methods/update-input-settings#type
const BACKGROUND_IMAGE_URL = "https://storage.googleapis.com/flowclub-static-dev/backgrounds/background2.png";

const BACKGROUNDS = {
  IMAGE: "image",
  BLUR: "blur",
  NONE: "none",
}

export const useBackgroundBlurOrImage = (callObject) => {
  const [ backgroundBlurOn, setBackgroundBlurOn ] = useState(localStorage.getItem(BACKGROUND_BLUR_KEY) === 'true')
  const [ backgroundImageOn, setBackgroundImageOn ] = useState(localStorage.getItem(BACKGROUND_IMAGE_KEY) === 'true')
  const sendSegmentEvent = useSendSegmentEvent()

  const participant = useLocalParticipant()
  const { session_id } = participant ?? { session_id: null }
  const videoTrack = useVideoTrack(session_id)

  const { inputSettings } = useInputSettings()

  useEffect(() => {
    if (callObject !== null && participant !== null && videoTrack.state === 'playable' && (inputSettings.video === undefined || inputSettings.video.processor.type === 'none')) {
      if (backgroundImageOn) {
        setCallBackgroundSetting(BACKGROUNDS.IMAGE)
      } else if (backgroundBlurOn) {
        setCallBackgroundSetting(BACKGROUNDS.BLUR)
      } else {
        setCallBackgroundSetting(BACKGROUNDS.NONE)
      }
    }
  }, [callObject, participant, inputSettings, videoTrack.state])

  const setCallBackgroundSetting = (backgroundSetting) => {
    let processor = {
      type: 'none',
    }
    if (backgroundSetting === BACKGROUNDS.IMAGE) {
      processor = {
        type: 'background-image',
        config: {
          source: BACKGROUND_IMAGE_URL,
        },
      }
    } else if (backgroundSetting === BACKGROUNDS.BLUR) {
      processor = {
        type: 'background-blur',
        config: { strength: BACKGROUND_BLUR_STRENGTH },
      }
    }
    callObject.updateInputSettings({
      video: {
        processor: processor,
      },
    })
  }

  const onToggleBackgroundBlur = () => {
    sendSegmentEvent("Background Blur Toggled", { togglingTo: !backgroundBlurOn })
    if (!backgroundBlurOn) {
      localStorage.setItem(BACKGROUND_BLUR_KEY, true)
    } else {
      localStorage.removeItem(BACKGROUND_BLUR_KEY)
    }
    if (!backgroundImageOn) {
      setCallBackgroundSetting(backgroundBlurOn ? BACKGROUNDS.NONE : BACKGROUNDS.BLUR)
    } // Otherwise do nothing for the actual call processor since image takes precedence
    setBackgroundBlurOn(backgroundBlurOn => !backgroundBlurOn)
  }

  const onToggleBackgroundImage = () => {
    sendSegmentEvent("Background Image Toggled", { togglingTo: !backgroundImageOn })
    if (!backgroundImageOn) {
      localStorage.setItem(BACKGROUND_IMAGE_KEY, true)
    } else {
      localStorage.removeItem(BACKGROUND_IMAGE_KEY)
    }
    setCallBackgroundSetting(!backgroundImageOn ? BACKGROUNDS.IMAGE : backgroundBlurOn ? BACKGROUNDS.BLUR : BACKGROUNDS.NONE)
    setBackgroundImageOn(backgroundImageOn => !backgroundImageOn)
  }

  return { backgroundBlurOn, backgroundImageOn, onToggleBackgroundBlur, onToggleBackgroundImage }
}