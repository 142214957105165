import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDaily } from '@daily-co/daily-react';

import { UserContext } from '../../UserProvider';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { useSetupLoungeSession } from '../../fetch/endpoints';
import { logDailyEvent } from '../../daily/logUtils';
import { useHotkeys } from 'react-hotkeys-hook';
import { IS_PROD } from '../../utils';
import { useBackgroundBlurOrImage } from '../../ParticipantApp/useBackgroundBlurOrImage';

const STATE_IDLE = 'STATE_IDLE';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_ERROR = 'STATE_ERROR';

/**
 * Gets [isCameraMuted, isMicMuted, isSharingScreen].
 * This function is declared outside Tray() so it's not recreated every render
 * (which would require us to declare it as a useEffect dependency).
 */
 function getStreamStates(callObject) {
  let isCameraMuted,
    isMicMuted,
    isSharingScreen = false;
  if (
    callObject &&
    callObject.participants() &&
    callObject.participants().local
  ) {
    const localParticipant = callObject.participants().local;
    isCameraMuted = !localParticipant.video;
    isMicMuted = !localParticipant.audio;
    isSharingScreen = localParticipant.screen;
  }
  return [isCameraMuted, isMicMuted, isSharingScreen];
}

export const useLoungeCall = ({ event, sessionWorkingTimeFinished, leaveSession }) => {
  const { user } = useContext(UserContext);
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callInitializationError, setCallInitializationError] = useState(null)
  const callObject = useDaily()
  const { chatOnly } = event

  /**
 * Show the call UI if we're either joining, already joined, or are showing
 * an error.
 */
  const showCall = [STATE_JOINING, STATE_JOINED].includes(
    appState
  );

  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */

  const enableCallButtons = [STATE_JOINED, STATE_ERROR].includes(appState);

  /**
   * Only enable the start button if we're in an idle state (i.e. not creating,
   * joining, etc.).
   *
   * !!!
   * IMPORTANT: only one call object is meant to be used at a time. Creating a
   * new call object with DailyIframe.createCallObject() *before* your previous
   * callObject.destroy() completely finishes can result in unexpected behavior.
   * Disabling the start button until then avoids that scenario.
   * !!!
   */
  const enableJoinScreen = appState === STATE_IDLE;

  /**
   * Creates a new call room.
   */
  const createCall = useCallback(() => {
    setAppState(STATE_CREATING);
  }, []);

  const [roomUrl, setRoomUrl] = useState(null);
  const [token, setToken] = useState(null);
  const { result: useSetupLoungeSessionResult } = useAutomaticallyFetch(useSetupLoungeSession, { eventId: event.id }, { condition: true  })
  const { meetingToken, dailyRoomName } = useSetupLoungeSessionResult ?? {token: null, dailyRoomName: null};
  
  useEffect(()=>{
    if (dailyRoomName && !roomUrl) {
      const url = `https://${IS_PROD ? 'flowclub' : 'flowclub-dev'}.daily.co/${dailyRoomName}`
      setRoomUrl(url);
      setToken(meetingToken);
    }
  }, [dailyRoomName])



  /**
   * Starts joining an existing call.
   *
   * NOTE: In this demo we show how to completely clean up a call with destroy(),
   * which requires creating a new call object before you can join() again.
   * This isn't strictly necessary, but is good practice when you know you'll
   * be done with the call object for a while and you're no longer listening to its
   * events.
   */
  const startJoiningCall = useCallback(async (url, token) => {
    muteMic()
    if (token) {
      // updatePageUrlWithRoomUrl()
      setAppState(STATE_JOINING);
      await callObject.join({
        url, token,
        receiveSettings: {
          base: { video: { layer: 1 } }
        }
      })
      callObject.setBandwidth({
        trackConstraints: { width: 640, height: 360, maxFramerate: 20 },
      });
    }
  }, [callObject]);

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        // leaveSession()
      });
    } else {
      if (sessionWorkingTimeFinished || window.confirm("Are you sure you want to break the flow?")) {
        callObject.leave();
        // leaveSession()
      }

    }
  }, [callObject, appState, sessionWorkingTimeFinished]);

  /**
   * If a room's already specified in the page's URL when the component mounts,
   * join the room.
   */
  useEffect(() => {
    if (user && user.uid && token) {
      const url = roomUrl
      if (url && !showCall) {
        startJoiningCall(url, token)
      }
    }
  }, [startJoiningCall, showCall, user, token]);

  /**
   * Uncomment to attach call object to window for debugging purposes.
   */
  // useEffect(() => {
  //   window.callObject = callObject;
  // }, [callObject]);

  /**
   * Update app state based on reported meeting state changes.
   *
   * NOTE: Here we're showing how to completely clean up a call with destroy().
   * This isn't strictly necessary between join()s, but is good practice when
   * you know you'll be done with the call object for a while and you're no
   * longer listening to its events.
   */
  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error'];

    function handleNewMeetingState(event) {
      if (event) { logDailyEvent(event); }

      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {

          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          console.error(event.errorMsg)
          setCallInitializationError("There was an error connecting to the session room. Please refresh the page and try again, or contact members@flow.club and we'll get this resolved.")
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  const videosAreaRef = useRef(null)

  const startEnteringCall = () => {
    if (event && token) {
      createCall();
      startJoiningCall(roomUrl, token);
    }
  }


  /* Extracted from old Tray.js */

  const [isCameraMuted, setCameraMuted] = useState(false);
  const [isMicMuted, setMicMuted] = useState(true);
  const [isSharingScreen, setSharingScreen] = useState(false);
  const [isMusicMuted, setMusicMuted] = useState(false);

  function toggleMusic() {
    setMusicMuted(!isMusicMuted);
  }

  function toggleCamera() {
    callObject.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    if (!chatOnly) {
      setMicMuted(!isMicMuted)
      callObject.setLocalAudio(isMicMuted);
    }
  }

  function muteMic() {
    if (callObject === null) { return }
    setMicMuted(true)
    callObject.setLocalAudio(false);
  }

  useHotkeys('ctrl+e, command+e', (e, handler) => {
    toggleCamera()
    return false
  }, [isCameraMuted])
  
  /**
   * Start listening for participant changes when callObject is set (i.e. when the component mounts).
   * This event will capture any changes to your audio/video mute state.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(
        callObject
      );
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
      setSharingScreen(isSharingScreen);
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    callObject.on('participant-updated', handleNewParticipantsState);

    // Stop listening for changes in state
    return function cleanup() {
      callObject.off('participant-updated', handleNewParticipantsState);
    };
  }, [callObject]);
  
  /* /Extracted from old Tray.js */
  // const { backgroundBlurOn, onToggleBackgroundBlur } = useBackgroundBlur(callObject)

  return {
    callObject,
    showCall,
    callInitializationError,
    enableCallButtons,
    enableJoinScreen,
    startLeavingCall,
    startEnteringCall,
    dailyMeetingToken: token,
    roomUrl,

    toggleMusic,
    toggleCamera,
    toggleMic,
    muteMic,
    isCameraMuted,
    isMicMuted,
    isMusicMuted,
    isSharingScreen,

    // backgroundBlurOn,
    // onToggleBackgroundBlur,
    
    videosAreaRef,
    createCall
  }
}