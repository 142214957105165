/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import '../fonts/fonts.scss';
import './App.scss'
import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { Toaster } from 'react-hot-toast';
import { InviteInfoWrapper } from '../InviteInfoWrapper';
import { MagnificentlyMunificentModalManager } from '../wrappers/MagnificentlyMunificentModalManager';
import { MainLoading } from '../commonLayout/MainLoading';
import { ParticlesManager } from '../wrappers/ParticlesManager';
import { SegmentProvider, useSendSegmentPageLoad } from '../wrappers/SegmentProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dayjs from 'dayjs'
import { useResetScroll } from './useResetScroll';
import { Routes } from './Routes';
import { useUpdateUserTimezone } from './useUpdateUserTimezone';
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime');
var timezone = require('dayjs/plugin/timezone');
var advancedFormat = require('dayjs/plugin/advancedFormat')
var dayJsDuration = require('dayjs/plugin/duration')
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(dayJsDuration)
dayjs.extend(customParseFormat)

const getWindowStateData = (user) => {
  const userTimezone = (user !== null && user.timezone) || dayjs.tz.guess()
  const userTimezoneOffset = (user !== null && user.timezoneOffset) || new Date().getTimezoneOffset()
  const localTime = dayjs().tz(userTimezone)
  const localTimeMS = localTime.diff(localTime.startOf('day'))

  return {
    windowVisible: document.visibilityState === 'visible',
    windowHasFocus: document.hasFocus(),
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    userTimezone,
    userTimezoneOffset,
    localTimeMS,
  }
}

function App() {
  const { loadingAuthState } = useContext(UserContext);

  const sendSegmentPageLoad = useSendSegmentPageLoad()
  const location = useLocation();
  useEffect(() => {
    sendSegmentPageLoad()
  }, [location]);

  useResetScroll()
  useUpdateUserTimezone()

  if (loadingAuthState) {
    return (
      <MainLoading />
    )
  }

  return (
    <SegmentProvider baseData={{ pathname: location.pathname }}>
      <Toaster containerStyle={{ bottom: 38 }} />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
        <InviteInfoWrapper>
          <ParticlesManager>
              <MagnificentlyMunificentModalManager>
                <Routes />
              </MagnificentlyMunificentModalManager>
          </ParticlesManager>
        </InviteInfoWrapper>
      </GoogleOAuthProvider>
    </SegmentProvider>
  );
}

const RouterAndSegmentWrapper = () => {
  const { user } = useContext(UserContext);
  return (
    <Router>
      <SegmentProvider baseData={() => ({
        loggedIn: user !== null,
        userIsHostInResidence: user !== null && user.isHostInResidence,
        ...(user !== null ? { subscriptionStatus: user.subscriptionStatus } : {}),
        ...getWindowStateData(user)
      })}>
        <App />
      </SegmentProvider>
    </Router>
  );
}

export default RouterAndSegmentWrapper;