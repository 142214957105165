/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES, LightGrayText, BUTTON_STYLES } from '../components/flowComponents';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import dayjs from 'dayjs';
import { adminBackground } from '../emotionVariables';
import { Link } from 'react-router-dom';
import { useGetActiveClubs, useCreateSessionsForClub, useGetMembersForClubs, useEmailUsersWhoHaveFavoritedHostAboutNewClub } from '../fetch/endpoints';
import { CLUB_MEMBERSHIP_ACTIVE_STATUSES, CLUB_MEMBERSHIP_STATUSES } from '../../functions/shared/constants';

export const ClubsOverviewAdmin = () => {

  const { result: clubs, performFetch, fetching, error } = useAutomaticallyFetch(useGetActiveClubs, {}, {})
  const refetchClubData = () => performFetch()
  const loadingFinished = useFetchSucceeded(fetching, error)

  return (
    <div css={css`display: flex; justify-content: center; padding-top: 24px;`}>
      <div css={css`display: flex; flex-direction: column; align-items: center;`}>
        <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H3}>Guided Clubs</Text>

        <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px; width: 100%;`}>
          {fetching && (
            <div css={css`display: flex; justify-content: center;`}>
              <Text>Loading guided clubs...</Text>
              <LoadingIndicator />
            </div>
          )}
          {loadingFinished && <DataTable  clubs={clubs} refetchClubData={refetchClubData} />}
        </div>
      </div>
    </div>
  )
}

const DataTable = ({ clubs, refetchClubData }) => {

  const { performFetch: createSessionsForClub, fetching, error } = useCreateSessionsForClub()
  const { performFetch: emailUsersWhoHaveFavoritedClubHost, fetching: fetchingEmailUsers, error: errorEmailingUsers } = useEmailUsersWhoHaveFavoritedHostAboutNewClub()
  const [ status, setStatus ] = useState('')
  const [ emailStatus, setEmailStatus ] = useState('')
  const [ selectedClub, setSelectedClub ] = useState(null)
  const clubIds = clubs.map(({ id }) => id)
  const { result: clubMembers, fetching: fetchingMembers, error: membersError } = useAutomaticallyFetch(useGetMembersForClubs, { clubIds })
  const [ showMembersIndex, setShowMembersIndex ] = useState(0)

  const createSession = async ({ clubId }) => {
    console.log("Creating sessions for club", clubId)
    setSelectedClub(clubId)
    const { success, result } = await createSessionsForClub({ clubId })
    setStatus('')
    if (success) {
      const { createdSessions, skippedSessions} = result
      setStatus(`Sessions created!\nCreated: ${createdSessions.length}\n${createdSessions.map(({startTime, title}) => `${dayjs(startTime).format('ddd, MMM D h:mm A')}: ${title}\n`)}\nSkipped: ${skippedSessions.length}\n${skippedSessions.join('\n')}`)
    } else {
      setStatus(`Error creating sessions: ${error}`)
    }
  }

  const emailUsersWhoFavoritedHost = async ({ clubId, dryRun }) => {
    console.log(`Emailing users who have favorited host for club ${clubId} - ${dryRun ? 'dry run' : 'for realsies'}.`)
    setSelectedClub(clubId)
    setEmailStatus('')
    const { success, result } = await emailUsersWhoHaveFavoritedClubHost({ clubId, dryRun })
    if (success) {
      setEmailStatus(`Emails sent to ${result.sent} users who have favorited host. Did not email ${result.notSent} users who don't have an active account or had followed just with an email.`)
    } else {
      setEmailStatus(`Error email users who favorited host: ${error}`)
    }
  }


  return (
    <>
    <Table striped bordered>
      <thead>
        <tr>
          <th>Club Name</th>
          <th>Payment type</th>
          <th>Club Host</th>
          <th>Club members</th>
          <th>Club dates</th>
          <th css={adminBackground}>Sessions created?</th>
          <th css={adminBackground}>Emailed users who favorited host?</th>
        </tr>
      </thead>
      <tbody>
        {clubs.map(({ id, name, paymentType, price, fullName, coachEmail, members, startDate, endDate, slug, sessions, sessionsCreated, sessionIds, sentEmailToUsersWhoFavoritedHost }, index) => {

          const thisClub = clubMembers ? clubMembers.find(club => club.clubId === id) : { members: []}
          const clubMembersForClub = thisClub ? thisClub.members : []
          const activeMembers = clubMembersForClub.filter(member => CLUB_MEMBERSHIP_ACTIVE_STATUSES.has(member.clubStatus))
          const interestedMembers = clubMembersForClub.filter(member => member.clubStatus === CLUB_MEMBERSHIP_STATUSES.INTERESTED)
          const pendingPaymentMembers = clubMembersForClub.filter(member => member.clubStatus === CLUB_MEMBERSHIP_STATUSES.PENDING_PAYMENT)

          const statusOrder = {
            [CLUB_MEMBERSHIP_STATUSES.HOST]: 1,
            [CLUB_MEMBERSHIP_STATUSES.ACTIVE]: 2,
            [CLUB_MEMBERSHIP_STATUSES.PENDING_PAYMENT]: 3,
            [CLUB_MEMBERSHIP_STATUSES.INTERESTED]: 4,
          }
          const clubMembersForClubSorted = clubMembersForClub.sort((a, b) => a.clubStatus === b.clubStatus ? a.joinedClub - b.joinedClub : statusOrder[a.clubStatus] - statusOrder[b.clubStatus])


          return (
            <tr key={id}>
              <td><Link to={`/guided-club/${slug}`} target="_blank" rel="noreferrer noopener">{name}</Link></td>
              <td>
                {paymentType} <br/>
                {price && `$${price/100}`}
              </td>
              <td>{fullName}<br/>
              <a href={`mailto:${coachEmail}`}>{coachEmail}</a></td>
              <td>
                {members} member{members === 1 ? '' : 's'}{interestedMembers.length > 0 ? ` (${interestedMembers.length} interested)` : ''}<br/><br/>
                {fetchingMembers ? <LoadingIndicator />
                :
                <>
                  {membersError ? <Alert variant="error">Error fetching members</Alert> :
                  <>
                    {clubMembers && clubMembers.length > 0 ?
                    <>
                    {activeMembers.length > 0 && <p><a target="_blank" rel="noreferrer noopener" href={`https://app.frontapp.com/compose?mailto=mailto:members@flow.club&from=members@flow.club&bcc=${activeMembers.map(({ email }) => email).join(',')}`}>Email all ACTIVE members ({activeMembers.length})</a></p>}
                    {interestedMembers.length > 0 && <p><a target="_blank" rel="noreferrer noopener" href={`https://app.frontapp.com/compose?mailto=mailto:members@flow.club&from=members@flow.club&&bcc=${interestedMembers.map(({ email }) => email).join(',')}`}>Email all INTERESTED members ({interestedMembers.length})</a></p>}
                    {pendingPaymentMembers.length > 0 && <p><a target="_blank" rel="noreferrer noopener" href={`https://app.frontapp.com/compose?mailto=mailto:members@flow.club&from=members@flow.club&bcc=${pendingPaymentMembers.map(({ email }) => email).join(',')}`}>Email all PENDING PAYMENT members ({pendingPaymentMembers.length})</a></p>}
                    {showMembersIndex !== index ? <FlowButton onClick={() => setShowMembersIndex(index)} buttonStyle={BUTTON_STYLES.OUTLINE_SECONDARY}>+ Show members</FlowButton> :
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Club Status</th>
                          <th>Joined Club</th>
                          <th>FC Status</th>
                          <th>Joined FC</th>
                        </tr>
                      </thead>
                      <tbody>
                      {clubMembersForClubSorted.map(({ id, displayName, email, clubStatus, joinedClub, subscriptionStatus, joined, membershipId}, i) => (
                        <tr key={id}>
                          <td><a href={`https://console.firebase.google.com/u/0/project/flowclub-21/firestore/data/~2FclubMemberships~2F${membershipId}`} target="_blank" rel="noreferrer noopener">{displayName}</a></td>
                          <td>{email}</td>
                          <td>{clubStatus}</td>
                          <td>{dayjs(joinedClub).format('ddd MMM D')}</td>
                          <td>{subscriptionStatus}</td>
                          <td>{dayjs(joined).format('ddd MMM D')}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>}</> : 'No members yet.'
                    }
                  </>}
                </>}
              </td>
              <td>{startDate ? dayjs(startDate).format('ddd MMM D') : 'Not set'} - {endDate ? dayjs(endDate).format('ddd MMM D') : 'Not Set'}</td>
              <td css={adminBackground}>
                {status && selectedClub === id &&
                <>
                <Alert show={status !== ''} variant={error ? "error": "success"} onClose={() => setStatus('')} dismissible>
                  {status}
                </Alert>
                <div css={css`margin: 8px 0px;`}>
                  <FlowButton onClick={() => refetchClubData()}>Refresh club data</FlowButton>
                </div>
                </>
                }
                {sessionsCreated ?
                <>
                  <LightGrayText>Created {dayjs(sessionsCreated).format('dddd, MMMM D @ h:mmA z')}</LightGrayText>
                  {sessionIds && 
                  <ul>

                  {sessionIds.map((sessionId, i) => (
                    <li>
                      <Link key={i} to={`/session/${sessionId}`} target="_blank" rel="noreferrer noopener">Session {i+1}</Link>
                    </li>
                  ))}
                  </ul>
                  }   
                </>
                :
                <>
                  {sessions === undefined || sessions.length === 0 ? <LightGrayText>No sessions to create</LightGrayText> :
                  <>
                  <FlowButton disabled={fetching} onClick={() => createSession({ clubId: id })}>{fetching && selectedClub === id ? "Creating..." : "Create sessions"}</FlowButton>
                  {sessions !== undefined && sessions.length > 0 &&
                  <div css={css`
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                  `}>
                    <Text style={TEXT_STYLES.APP_H6}>Sessions to Create ({sessions.length}):</Text>
                    <LightGrayText>All times in {dayjs().format("z")} ({dayjs.tz.guess()})</LightGrayText>
                    <ul>
                      {sessions.map(({startTime, title}, i) => (
                        <li key={i}>{dayjs(startTime).format('ddd, MMM D h:mm A')}: {title} </li>
                      ))}
                    </ul>
                  </div>
                  }
                  </>
                  }
                </>}
              </td>
              <td>
              {emailStatus && selectedClub === id &&
                <>
                <Alert show={emailStatus !== ''} variant={error ? "error": "success"} onClose={() => setEmailStatus('')} dismissible>
                  {emailStatus}
                </Alert>
                <div css={css`margin: 8px 0px;`}>
                  <FlowButton onClick={() => refetchClubData()}>Refresh club data</FlowButton>
                </div>
                </>
              }
              { sentEmailToUsersWhoFavoritedHost ?
                   <LightGrayText>Email users who favorited host {dayjs(sentEmailToUsersWhoFavoritedHost).format('dddd, MMMM D @ h:mmA z')}</LightGrayText>
                :
                  <div css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                  `}>
                    <FlowButton buttonStyle={BUTTON_STYLES.GREEN_OUTLINE} disabled={fetchingEmailUsers} onClick={() => emailUsersWhoFavoritedHost({ clubId: id, dryRun: true })}>{fetchingEmailUsers && selectedClub === id ? "Dry-running..." : "Dry-run (see counts)"}</FlowButton>
                    <FlowButton buttonStyle={BUTTON_STYLES.GREEN} disabled={fetchingEmailUsers} onClick={() => emailUsersWhoFavoritedHost({ clubId: id, dryRun: false })}>{fetchingEmailUsers && selectedClub === id ? "Emailing users (if not dry run)..." : "Email users who favorited host"}</FlowButton>
                  </div>
                }
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    </>
  )
}
