/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {useEffect} from 'react'
import { useContext } from "react";
import { LoungeContext } from "./LoungeContext";
import { useModal } from "../wrappers/MagnificentlyMunificentModalManager";
import { LoungeFullscreenModal } from "./LoungeFullscreenModal";
import { FlowButton, Modal, SIZE_PRESETS } from '../components/flowComponents';
import { NEUTRAL_20 } from "../emotionVariables";
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import {
  useSendSegmentEvent,
} from "../wrappers/SegmentProvider";
import { useMediaQuery } from 'react-responsive';

const LoungeCtaModal = () => {

  const { ctaModalOpen, toggleCtaModalOpen } = useContext(LoungeContext);
  const { setActiveModal, clearModal } = useModal();
  const sendSegmentEvent = useSendSegmentEvent()

  const openLoginSignupModal = (startInLoginMode) => {
    setActiveModal(LoginSignupModal, {
      onHide: () => {
        toggleCtaModalOpen(false);
      },
      onSuccess: () => {
        clearModal();

        // Refreshes page upon successful signup in order to re-join the Daily call with propery credentials
        // There's probably a more clever way to achieve this without a reload, but for simplicity this works
        window.location.reload();
      },
      startInLoginMode,
      showLoginModeLink: true,
      showBookingRelatedText: false
    })
  }
  
  useEffect(() => {
    if (ctaModalOpen) {
      sendSegmentEvent("Anonymous Lounge User Clicked CTA Button")
      setActiveModal(LoungeFullscreenModal, {
        Contents: Content,
        overlayColor: "#091454aa",
        onHide: () => {
          toggleCtaModalOpen(false);
        },
        onConfirm: () => {
          toggleCtaModalOpen(false);
          openLoginSignupModal(false);
          sendSegmentEvent("Anonymous Lounge User Clicked Signup")
        }
      });
    }
  }, [ctaModalOpen]);

  return (
    <></>
  )
}

export default LoungeCtaModal


export const Content = ({ Contents, onConfirm, onHide }) => {
  const { toggleCtaModalOpen } = useContext(LoungeContext);
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})

  const handleOnHide = () => {
    toggleCtaModalOpen(false);
    onHide();
  }

  return (
    <Modal onClose={handleOnHide}>
      <div css={css`
        ${!mobileView && css`width: 500px;`}
        max-width: 100%;
        padding: 30px;
        font-size: 16px;
        color: black;
      `}>
        <h2 css={css`font-size: 24px`}>
          Unlock all features with a Flow Club membership.
        </h2>
        <p css={css`margin: 18px 0`}>
          Turning your camera on in the Lounge requires a full Flow Club membership.
        </p>
        <h3 css={css`font-size: 18px`}>Flow Club members also get full access to:</h3>
        <ul>
          <li>24/7 dedicated co-working sessions</li>
          <li>a community of 313 dedicated session hosts</li>
          <li>regularly scheduled sessions to keep you accountable</li>
        </ul>
        
        <footer css={css`
          margin: 30px -30px -30px -30px;
          background: ${NEUTRAL_20};
          padding: 18px;
          display: flex;
          justify-content: flex-end;
        `}>
          <FlowButton onClick={onConfirm} sizePreset={SIZE_PRESETS.LARGE} css={css``}>
            Try for Free
          </FlowButton>
        </footer>

      </div>
    </Modal>
  )
}