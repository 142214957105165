/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { DarkText, FlowButton, SIZE_PRESETS, TextInput, TEXT_INPUT_TYPES } from '../components/flowComponents';
import { EntryPage } from './EntryPage';
import firebase from 'firebase/app';
import { useQuery } from '../utils';
import { BANNER_STYLES } from '../components/banners';
import { useHistory } from 'react-router';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { Link } from 'react-router-dom';
import { baseURL } from '../firebase';
import { MainLoading } from '../commonLayout/MainLoading';

const firebaseAuth = firebase.auth()

export const ResetPasswordAction = () => {

  const query = useQuery()

  const queryParams = ['mode', 'oobCode', 'continueUrl']
  const [mode, actionCode, continueUrl] = queryParams.map(paramName => query.get(paramName))
  const redirect = continueUrl !== null ? continueUrl.slice(baseURL.length) : null

  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadEmailFromResetLink()
  }, [mode, actionCode, continueUrl])

  const history = useHistory()
  const redirectFromInvalidResetLink = () => history.push('/reset-password?error=invalid-link')

  async function loadEmailFromResetLink() {
    if (mode === 'resetPassword') {
      try {
        const accountEmail = await firebaseAuth.verifyPasswordResetCode(actionCode)
        setEmail(accountEmail)
      } catch (error) {
        redirectFromInvalidResetLink()
      }
    } else {
      redirectFromInvalidResetLink()
    }
  }

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [passwordSuccessfullyReset, setPasswordSuccessfullyReset] = useState(false)

  const [resetInProgress, setResetInProgress] = useState(false)

  const initialLoading = email === null && error === null
  if (initialLoading) {
    return <MainLoading/>
  }


  const onSubmit = async (event) => {
    event.preventDefault()
    if (newPassword !== newPasswordConfirmation) {
      setError("Passwords entered don't match.")
      return
    }

    setResetInProgress(true)

    try {
      // return value is undefined on success
      await firebaseAuth.confirmPasswordReset(actionCode, newPassword)
      setPasswordSuccessfullyReset(true)
    } catch (error) {
      setError(translateAuthErrorCodeToMessage(error.code))
    }
    setResetInProgress(false)
  }

  if (!passwordSuccessfullyReset) {
    return <NewPasswordInput error={error} onSubmit={onSubmit} email={email} newPassword={newPassword} newPasswordConfirmation={newPasswordConfirmation} setNewPassword={setNewPassword} setNewPasswordConfirmation={setNewPasswordConfirmation} resetInProgress={resetInProgress} />
  } else {
    return <SuccessScreen redirect={redirect}/>
  }
}

const NewPasswordInput = ({ error, onSubmit, email, newPassword, newPasswordConfirmation, setNewPassword, setNewPasswordConfirmation, resetInProgress }) => {

  const disabled = !(newPassword.length > 0 && newPasswordConfirmation.length > 0)

  return (
    <EntryPage bannerContent={error} bannerStyle={BANNER_STYLES.error}>
      <div>
        <form onSubmit={onSubmit}>
          <h2 css={css`margin-bottom: 28px;`}>Password Reset</h2>
          <DarkText customCss={css`margin-bottom: 20px;`}>
            Password reset for <span css={css`font-weight: 700;`}>{email}</span>
          </DarkText>
          <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} placeholder='Enter new password' value={newPassword} onChange={event => setNewPassword(event.target.value)} label={'New Password'} type={TEXT_INPUT_TYPES.PASSWORD}></TextInput>
          <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 16px;`} placeholder='Confirm password' value={newPasswordConfirmation} onChange={event => setNewPasswordConfirmation(event.target.value)} label={'Confirm New Password'} type={TEXT_INPUT_TYPES.PASSWORD}></TextInput>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} disabled={disabled} loading={resetInProgress} onClick={onSubmit} type={'submit'}>Reset Password</FlowButton>
        </form>
      </div>
    </EntryPage>
  )
}

const SuccessScreen = ({ redirect }) => {
  return (
    <EntryPage>
      <h2 css={css`margin-bottom: 28px;`}>Password Changed</h2>
      <DarkText customCss={css`margin-bottom: 36px;`}>Awesome! You can now log in with your new password</DarkText>
      <Link to={`/login${redirect !== null ? `?redirect=${redirect}` : '' }`}><FlowButton sizePreset={SIZE_PRESETS.CHUNKY} fillAvailableWidth>Log In</FlowButton></Link>
    </EntryPage>
  )
}