/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { DescriptivelyNamedPurpleBanner, YetAnotherBanner, YetYeetAnotherBanner } from './components/banners'
import { BUTTON_STYLES, Card, DropdownToggleButton, FlowButton, SIZE_PRESETS, Text, TextInput, TEXT_INPUT_STYLES, TEXT_STYLES } from './components/flowComponents'
import Icon, { 
  TYPE_CAMERA_OFF,
  TYPE_CAMERA_ON,
  TYPE_MIC_OFF,
  TYPE_MIC_ON,
  TYPE_MUSIC_SHARE_ON,
  TYPE_MUSIC_SHARE_ON_UNSET_FILL,
  TYPE_MUSIC_SHARE_OFF,
  TYPE_MUSIC_ON,
  TYPE_MUSIC_ON_UNSET_FILL,
  TYPE_MUSIC_OFF,
  TYPE_LEAVE,
  TYPE_CHAT,
  TYPE_CHAT_NOTIFICATION,
  TYPE_MORE,
  TYPE_RIGHT_CARET,
  TYPE_ATTENDEES,
  TYPE_CLOSE,
  TYPE_CLOSE_UNSET_FILL,
  TYPE_STARS,
  TYPE_STAR,
  TYPE_STAR_IN_CIRCLE,
  TYPE_GIFT,
  TYPE_SETTINGS_ACCOUNT,
  TYPE_SETTINGS_PROFILE,
  TYPE_SETTINGS_HOSTING,
  TYPE_SETTINGS_MEMBERSHIP,
  TYPE_SETTINGS_REFERRALS,
  TYPE_HAMBURGER_MENU,
  TYPE_CHECKMARK,
  TYPE_HIGH_FIVE_HAND,
  TYPE_DRAG_HANDLE,
  TYPE_PICTURE_IN_PICTURE,
  TYPE_MAKING_TIME_FOR,
  TYPE_CALENDAR,
  TYPE_CLOCK,
  TYPE_BLUR_ON,
  TYPE_BLUR_OFF,
  TYPE_OUTLINK,
  TYPE_PLAY,
  TYPE_LINKEDIN_CIRCLE,
  TYPE_LINKEDIN_SQUARE,
  TYPE_TWITTER_CIRCLE,
  TYPE_TWITTER_SQUARE,
  TYPE_CLIPBOARD,
  TYPE_PASTE,
  TYPE_NO_PHONE,
  TYPE_LINK,
  TYPE_HELP,
  TYPE_MIRROR,
  TYPE_MIRROR_OFF,
  TYPE_FLAG,
  TYPE_SHOW,
  TYPE_HIDE,

} from './daily/daily-components/Icon/Icon'
import { ALL_COLORS } from './emotionVariables'
import { useClickOutside } from './Sessions/Schedule/useClickOutside'
import { Banner } from './shared/components/banner'


export const ComponentsDemo = () => {
  const [backgroundColor, setBackgroundColor] = useState('plum')

  return (
    <div css={css`
      width: 100vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background-color: ${backgroundColor};
      padding: 40px;
    `}>
      <BackgroundControl backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor}/>
      <Banners/>
      <Buttons/>
      <TextInputs/>
      <Dropdowns/>
      <Icons/>
      <TextStyles/>
      <Containers/>
      <Colors/>
    </div>
  )
}

const BackgroundControl = ({ backgroundColor, setBackgroundColor }) => {
  return (
    <div css={css`
      display: flex;
      justify-content: flex-end;
      width: 75%;
    `}>
      
      <TextInput label={'Background color (css value -- name or #ffffff or rgba(0, 0, 0, 0.65) form)'} value={backgroundColor} onChange={(event) => setBackgroundColor(event.target.value)} />
    </div>
  )
}

const Banners = () => {
  const [showFixedBanner, setShowFixedBanner] = useState(false)
  return (
    <div css={css`width: 100%; margin-bottom: 40px;`}>
      <h1 css={css`text-align: center;`}>Banners</h1>
      <Banner>used for the "You've unlocked hosting!" banner</Banner>
      <YetAnotherBanner>used on the session details page, session join screen/lobby and host session creation confirmation screen</YetAnotherBanner>
      <DescriptivelyNamedPurpleBanner>used on the host profile and the 'trial time remaining' indicator</DescriptivelyNamedPurpleBanner>
      <DescriptivelyNamedPurpleBanner>(there's also one more type of banner, used on the login pages, but it's hard to display here because it's fixed to the top of the page and gets a bit annoying:) <FlowButton customCss={css`display: inline;`} onClick={() => setShowFixedBanner(!showFixedBanner)}>show/hide</FlowButton></DescriptivelyNamedPurpleBanner>
      {showFixedBanner && <YetYeetAnotherBanner>hello. I am a very floaty banner</YetYeetAnotherBanner>}
    </div>
  )
}

const Buttons = () => {
  const buttonStyles = Object.keys(BUTTON_STYLES).filter(style => Number.isNaN(parseInt(style)))
  return (
    <div>
      <h1 css={css`text-align: center;`}>Buttons</h1>
      <div css={css`display: flex; padding: 20px; width: 80vw; align-items: center;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>38px</div>
        </div>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-right: 16px; flex: 1;`} buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>48px</div>
        </div>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-right: 16px; flex: 1;`} sizePreset={SIZE_PRESETS.CHUNKY} buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>55px</div>
        </div>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-right: 16px; flex: 1; height: 55px;`} buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>disabled</div>
        </div>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-right: 16px; flex: 1;`} disabled buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>loading</div>
        </div>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-right: 16px; flex: 1;`} loading buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold;`}>
          <div>width-expanding</div>
        </div>
      <div css={css`display: flex; padding: 20px; width: 80vw; flex-direction: column;`}>
        {buttonStyles.map(style => <FlowButton customCss={css`margin-bottom: 16px;`} fillAvailableWidth buttonStyle={BUTTON_STYLES[style]}>{style}</FlowButton>)}
      </div>
    </div>
  )
}

const Icons = () => {
  const icons = [
    TYPE_CAMERA_OFF,
    TYPE_CAMERA_ON,
    TYPE_MIC_OFF,
    TYPE_MIC_ON,
    TYPE_MUSIC_SHARE_ON,
    TYPE_MUSIC_SHARE_ON_UNSET_FILL,
    TYPE_MUSIC_SHARE_OFF,
    TYPE_MUSIC_ON,
    TYPE_MUSIC_ON_UNSET_FILL,
    TYPE_MUSIC_OFF,
    TYPE_LEAVE,
    TYPE_CHAT,
    TYPE_CHAT_NOTIFICATION,
    TYPE_MORE,
    TYPE_RIGHT_CARET,
    TYPE_ATTENDEES,
    TYPE_CLOSE,
    TYPE_CLOSE_UNSET_FILL,
    TYPE_STARS,
    TYPE_STAR,
    TYPE_STAR_IN_CIRCLE,
    TYPE_GIFT,
    TYPE_SETTINGS_ACCOUNT,
    TYPE_SETTINGS_PROFILE,
    TYPE_SETTINGS_HOSTING,
    TYPE_SETTINGS_MEMBERSHIP,
    TYPE_SETTINGS_REFERRALS,
    TYPE_HAMBURGER_MENU,
    TYPE_CHECKMARK,
    TYPE_HIGH_FIVE_HAND,
    TYPE_DRAG_HANDLE,
    TYPE_PICTURE_IN_PICTURE,
    TYPE_MAKING_TIME_FOR,
    TYPE_CALENDAR,
    TYPE_CLOCK,
    TYPE_BLUR_ON,
    TYPE_BLUR_OFF,
    TYPE_OUTLINK,
    TYPE_PLAY,
    TYPE_LINKEDIN_CIRCLE,
    TYPE_LINKEDIN_SQUARE,
    TYPE_TWITTER_CIRCLE,
    TYPE_TWITTER_SQUARE,
    TYPE_CLIPBOARD,
    TYPE_PASTE,
    TYPE_NO_PHONE,
    TYPE_LINK,
    TYPE_HELP,
    TYPE_MIRROR,
    TYPE_MIRROR_OFF,
    TYPE_FLAG,
    TYPE_SHOW,
    TYPE_HIDE,
  ]

  const [iconColor, setIconColor] = useState('greenyellow')
  const [iconSize, setIconSize] = useState(44)

  return (
    <div css={css`text-align: center;`}>
      <h1>Icons</h1>
      <div css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-bottom: 20px;
      `}>
        <TextInput wrapperCustomCss={css`margin-bottom: 20px;`} label={'icon size (should work for most icons, albeit probably inconsistently)'} value={iconSize} onChange={(event) => setIconSize(event.target.value)} />
        <TextInput label={'icon color (only works for newer & retrofitted icons 🙃)'} value={iconColor} onChange={(event) => setIconColor(event.target.value)} />
      </div>
      <div css={css`display: flex; flex-wrap: wrap; width: 80vw;`}>
        {icons.map(icon =>
          <div css={css`width: 100px; margin-right: 20px; padding: 10px; display: flex; flex-direction: column; align-items: center;`}>
            <div>{icon}</div>
            <Icon css={css`fill: ${iconColor}; width: ${iconSize}px; height: ${iconSize}px;`} type={icon}/>
          </div>
        )}
      </div>
    </div>
  )
}

const TextInputs = () => {
  const inputStyles = Object.keys(TEXT_INPUT_STYLES).filter(style => Number.isNaN(parseInt(style)))

  return (
    <div css={css`padding: 30px;`}>
      <h1 css={css`text-align: center;`}>Text Inputs</h1>
      <div css={css`display: flex; padding: 20px; width: 80vw; align-items: center;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>38px</div>
        </div>
        {inputStyles.map(style => <TextInput wrapperCustomCss={css`margin-right: 16px; flex: 1;`} inputStyle={TEXT_INPUT_STYLES[style]} label={style} placeholder={"placeholder text"}/>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw; align-items: center;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>48px</div>
        </div>
        {inputStyles.map(style => <TextInput sizePreset={SIZE_PRESETS.CHUNKY} wrapperCustomCss={css`margin-right: 16px; flex: 1;`} inputStyle={TEXT_INPUT_STYLES[style]} label={style} placeholder={"placeholder text"}/>)}
      </div>
      <div css={css`display: flex; padding: 20px; width: 80vw; align-items: center;`}>
        <div css={css`display: flex; justify-content: center; align-items: center; padding: 8px; font-size: 26px; font-weight: bold; width: 130px;`}>
          <div>password</div>
        </div>
        {inputStyles.map(style => <TextInput password wrapperCustomCss={css`margin-right: 16px; flex: 1;`} inputStyle={TEXT_INPUT_STYLES[style]} label={style} placeholder={"placeholder text"}/>)}
      </div>
    </div>
  )
}

const Dropdowns = () => {
  const Content = ({ openButtonRef, closeDropdown }) => {
    const clickOutsideRef = useClickOutside({ onTriggered: closeDropdown, additionalIgnoredRefs: [openButtonRef] })

    return (
      <div ref={clickOutsideRef} css={css`
        width: 100%;
        height: 100%;

        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      `}>
        <div css={css`margin-bottom: 20px;`}>hello! i am some fold-out-y content</div>
        <div>i can be closed by clicking elsewhere or pressing escape</div>
      </div>
    )
  }

  return (
    <div>
      <h1 css={css`text-align: center;`}>Dropdowns</h1>
      <DropdownToggleButton label={'Dropdown'} customCss={css`width: unset;`}>
        <Content/>
      </DropdownToggleButton>
    </div>
  )
}

const TextStyles = ({ }) => {
  const textStyles = Object.keys(TEXT_STYLES)

  const [text, setText] = useState("lorem ipsum")

  return (
    <div css={css`width: 80vw; display: flex; flex-direction: column; align-items: center;`}>
      <h1 css={css`text-align: center;`}>Text Styles</h1>
      <div css={css`width: 100%; display: flex; flex-direction: column; align-items: flex-end;`}>
        <TextInput value={text} onChange={event => setText(event.target.value)}/>
      </div>
      {textStyles.map(style => <div css={css`display: flex; align-items: center;`}>
        <div css={css`width: 150px;`}>{style}</div>
        <Text style={TEXT_STYLES[style]}>{text}</Text>
      </div>)}
    </div>
  )
}

const Colors = ({ }) => {
  const colors = Object.keys(ALL_COLORS)

  return (
    <div css={css`width: 80vw; display: flex; flex-direction: column; align-items: center;`}>
      <h1 css={css`text-align: center;`}>Colors</h1>
      <div css={css`
        display: flex;
        flex-wrap: wrap;
      `}>
        {colors.map(color => <div css={css`margin-right: 28px;`}>
          <div>{color}</div>
          <div>{ALL_COLORS[color]}</div>
          <div css={css`display: flex; align-items: center; justify-content: center; width: 80px; height: 80px; background-color: ${ALL_COLORS[color]};`} />
        </div>)}
      </div>
    </div>
  )
}

const Containers = ({ }) => {
  return (
    <div css={css`width: 80vw;`}>
     <h1 css={css`text-align: center;`}>Containers</h1>
      <Card customCss={css`background-color: grey;`}>
        <Card customCss={css`
          margin-bottom: 20px;
        `}>things</Card>
        <Card customCss={css`
          margin-bottom: 20px;
        `}>stuff</Card>
        <Card customCss={css`
          margin-bottom: 20px;
          display: flex;
        `}>
          <Card customCss={css`
            margin-right: 20px;
            background-color: olive;
          `}>even more</Card>
          <Card customCss={css`
            background-color: olive;
          `}>things</Card>
        </Card>
        <Card>more stuff</Card>
      </Card>
    </div>
  )
}