/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { LinkStyledText } from "./components/flowComponents";
import Icon, { TYPE_CLOSE, TYPE_STARS } from "./daily/daily-components/Icon/Icon";
import { useRequestTheme } from "./fetch/endpoints";
import { useFetchSucceeded } from "./fetch/helpers";
import "./RequestATheme.scss"

// These are experiments with reusable UI components, that may or may not end up in the trash
// What I've determined from this experiment is that I still don't like CSS
const Label = ({children, style = {}}) => {
  return <div style={style} className="fc-forms-label">{children}</div>
}

const Input = ({ style = {}, className = "", placeholder, textAreaHeight = null, value, onChange }) => {
  const textAreaStyle = {
    resize: "none",
    height: textAreaHeight,
    ...style
  }
  return textAreaHeight !== null ?
    <textarea value={value} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} className={`form-control fc-forms-input fc-forms-text-input ${className}`} style={textAreaStyle} /> :
    <input    value={value} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} className={`form-control fc-forms-input fc-forms-text-input ${className}`} style={style} type="text" />
}

const NumberInput = ({ style = {}, placeholder = "", value, onChange, min, max }) => {
  const inputChanged = (event) => {
    const input = event.target.value
    const intInput = parseInt(input, 10)
    // maybe overdesigned, but
    // solutions for getting a native html number input to not display increase/decrease arrow controls seemed a bit hacky/browser-specific
    // so just doing some simple is-a-number (or blank, so editing isn't a pain) validation here and calling it a day
    if (input.match(/^[0-9]*$/) !== null && intInput >= min && intInput <= max) {
      onChange(intInput)
    } else if (input === "") {
      onChange(input)
    }
  }
  return (
    <input value={value} onChange={inputChanged} placeholder={placeholder} className="form-control fc-forms-input fc-forms-number-input" style={style} type="text" />
  )
}

const AgendaItem = ({ value, deletable, onDurationChange, onDescriptionChange, onDelete}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <NumberInput value={value.duration} onChange={onDurationChange} min="0" max="1000" />
      <div style={{color: "rgba(0, 0, 0, 0.6)", padding: "9px 26px 9px 8px"}}>min</div>
      <Input value={value.name} onChange={onDescriptionChange} style={{margin: "4px 0px"}} type="text" />
      {deletable && <span onClick={onDelete}><Icon style={{ cursor: "pointer", width: "38px" }} type={TYPE_CLOSE}/></span>}
    </div>
  )
}

const TotalDuration = ({duration}) => {
  return (
    <div style={{ display: "flex", color: "rgba(0, 0, 0, 0.6)" }}>
      <div style={{ width: "60px", height: "42px", padding: "9px 19px", textAlign: "right" }}>{duration}</div>
      <div style={{ padding: "9px 26px 9px 8px" }}>min</div>
    </div>
  )
}

const defaultAgenda = [
  {
    duration: 5,
    name: "Share goals and tasks",
  },
  {
    duration: 50,
    name: "Muted deep work",
  },
  {
    duration: 5,
    name: "Debrief and celebrate",
  },
]

const AgendaInputs = ({ agendaItems, setAgendaItems }) => {
  const onDurationChange = (value, changedIndex) => {
    setAgendaItems(agendaItems.map((agendaItem, index) => index === changedIndex ? {...agendaItem, duration: value} : agendaItem))
  }
  const onDescriptionChange = (value, changedIndex) => {
    setAgendaItems(agendaItems.map((agendaItem, index) => index === changedIndex ? {...agendaItem, name: value} : agendaItem))
  }
  const onDelete = (changedIndex) => {
    setAgendaItems(agendaItems.filter((agendaItem, index) => index !== changedIndex))
  }

  return (
    <>
      <Label style={{marginBottom: "14px"}}>Agenda</Label>
      {agendaItems.map((agendaItem, index) =>
        <AgendaItem
          key={index}
          value={agendaItem}
          deletable={agendaItems.length > 1}
          onDurationChange={(value) => onDurationChange(value, index)}
          onDescriptionChange={(value) => onDescriptionChange(value, index)}
          onDelete={() => onDelete(index)}
        />
      )}

    </>
  )
}

export const RequestATheme = () => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [agendaItems, setAgendaItems] = useState(defaultAgenda)

  const addAgendaItem = () => {
    setAgendaItems([...agendaItems, {duration: 0, name: ""}])
  }

  const totalDuration = agendaItems.reduce((total, {duration}) => total + (duration || 0), 0)

  const {
    performFetch: requestTheme,
    fetching,
    error
  } = useRequestTheme()
  const successfullySubmitted = useFetchSucceeded(fetching, error)

  if (successfullySubmitted) {
    return <SuccessScreen/>
  } else {
    return (
      <Container>
        <div style={{ margin: "48px 0px", maxWidth: "392px" }}>
          <div style={{ fontFamily: "Sato", fontSize: "34px", marginBottom: "40px" }}>Request a Theme</div>
          <Label>Title</Label>
          <Input value={title} onChange={setTitle} placeholder="e.g. Inbox Zero, Reading" />
          <Label>Description</Label>
          <Input value={description} onChange={setDescription} textAreaHeight={"105px"} placeholder="e.g. Reading Flow Clubbers will start the session by sharing the chapters or pages they’d like to read in the session." />
          <AgendaInputs agendaItems={agendaItems} setAgendaItems={setAgendaItems} />
          <div style={{ display: "flex" }}>
            <TotalDuration duration={totalDuration} />
            <LinkStyledText customCss={css`padding: 9px 8px;`} onClick={addAgendaItem}>Add agenda item</LinkStyledText>
          </div>
          <Button
            variant="primary"
            disabled={fetching}
            onClick={() => requestTheme({title, description, agendaItems})}
            style={{ width: "160px", marginTop: "47px" }}
          >
            {fetching ? "Submitting..." : "Submit Request"}
          </Button>
          {error !== null &&
            <Alert style={{ marginTop: "30px" }} variant="danger">
              There was an error submitting your suggestion. Please try again or contact <a href="mailto:hosts@flow.club">hosts@flow.club</a> and we'll get this resolved.
            </Alert>}
        </div>
      </Container>
    )
  }
}

const SuccessScreen = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", marginTop: "48px"}}>
      <div style={{maxWidth: "406px", textAlign: "center"}}>
        <Icon type={TYPE_STARS} />
        <div style={{ fontFamily: "Sato", fontSize: "34px", margin: "34px 0px 27px 0px", color: "rgba(0, 0, 0, 0.87)" }}>Thanks for your suggestion!</div>
        <div style={{color: "rgba(0, 0, 0, 0.6)", letterSpacing: "0.5px"}}>We appreciate your suggestion and we’ll be sure to review it. Your theme will appear as an option for all hosts if your theme is approved! </div>
        <Button
          href="/upcoming/"
          variant="primary"
          style={{ margin: "48px 0px" }}
        >
          Browse the Schedule
        </Button>
      </div>
    </div>
  )
}