/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import '../Schedule.scss';
import './SessionModal.scss';
import { Link } from 'react-router-dom';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useContext, useMemo } from 'react';
import dayjs from 'dayjs'
import { UserContext, usePreferences } from '../../UserProvider';
import { CalendarGoalEntry } from '../../components/CalendarGoalEntry';
import { DarkText } from '../../components/flowComponents';

const loadingMessages = [
  "Studies show that group flow is more enjoyable than solitary flow. 👯🌊",
  "Putting Do Not Disturb on the universe for you 🎧🪐",
  "Carving out time for real work 🔪⏳",
  "Making Slack go down so you can have peace 💬🔻",
  "Summoning extra energy and creativity for your session ⚡️👩🏻‍🎨",
  "Remember to schedule some breaks throughout the day too!🧘🏻‍♀️⏳",
  "While you wait, take a deep breath. You're doing great! 🧘🏻‍♀️👍",
]

export function BookSessionModalContent({ event, onHide, loading, error }) {
  const { user } = useContext(UserContext)
  const randomLoading = useMemo(() => loadingMessages[Math.floor(Math.random() * loadingMessages.length)], [event]);

  return (
    <Modal
      onHide={() => loading ? (() => {})() : onHide()}
      show
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={!loading}>
        <Modal.Title as="h2">
          {
            loading ? "Booking your session..." :
              (error ? "Error booking session" : "You're confirmed!")
          }
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <h4>
            <Spinner animation="grow" variant="primary" className="mr-3" />
            {randomLoading}
          </h4>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            {error !== null ?
              <BookingError error={error}/> :
              <BookingSuccess event={event} />
            }
          </Modal.Body>
          <Modal.Footer className="border-0">
            {error === null && user && user.subscriptionStatus && user.subscriptionStatus.startsWith('trialing') && user.trialEnd ?
              <a href="/plans/">You have {dayjs(user.trialEnd.toDate()).fromNow(true)} left on your free trial.</a>
              : null
            }
            <Button onClick={onHide}>{error === null ? 'Save and Close' : 'Close'}</Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

const BookingSuccess = ({ event }) => {
  const { disableCalendarInvites } = usePreferences()
  if (!event) {
    return <Spinner animation="grow" variant="primary" />
  }
  return (
    disableCalendarInvites ?
    <div>
       Calendar invites turned off in <Link to="/settings/account">settings</Link>.<br/>
        Access this session from the top of the schedule or from <Link to="/profile/sessions">My Sessions</Link>.
    </div>
  :
    <div>
      We'll send you a calendar invite for
      <div>
        {event.start ? dayjs(event.start).format('ddd, MMM D @ h:mma z') : ''}.
      </div>
      <br />
      <CalendarGoalEntry eventId={event.id} />
    </div>
  )
}

const BookingError = ({ error }) => {
  return (
    <div>
      <div css={css`
        color: #A55528;
        background-color: #FFEEE4;
        padding: 12px 16px;
      `}>
        {error}
      </div>
      <DarkText customCss={css`margin-top: 20px;`}>Please try again or <a href="mailto:help@flow.club">email us for help.</a></DarkText>
    </div>
  )
}