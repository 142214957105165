/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { useSetUserPreferences } from '../fetch/endpoints';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { EnterNameModal } from '../ParticipantApp/EnterNameModal';

export const NewUserWelcomePrompt = ({ onHide }) => {
  // prefill values from inviteInfo if available
  const inviteInfo = useContext(InviteInfoContext)
  const prepopulatedFields = {
    email: inviteInfo.email,
    firstName: inviteInfo.firstName,
    lastName: inviteInfo.lastName
  }

  const { performFetch: setUserPreference } = useSetUserPreferences()
  const setWelcomeMessageSeen = () => {
    setUserPreference({ newPreferenceData: { spaceWelcomeMessageSeen: true } })
  }

  const onSuccess = () => {
    onHide()
    // set this to seen so that totally new users don't get the general welcome message on top of this signup prompt
    setWelcomeMessageSeen()
  }

  return (
    <EnterNameModal onHide={onHide} onSuccess={onSuccess} prepopulatedFields={prepopulatedFields} contentOverrides={{
      title: 'Welcome',
      description: 'Tell us about yourself to get started.',
      submitButtonText: "Join Lounge"
    }} />
  )
}