/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext } from 'react';

import { UserContext } from '../../UserProvider';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import { LinkStyledText } from '../../components/flowComponents';
import { DescriptivelyNamedPurpleBanner } from '../../components/banners';
import { LoginSignupModal } from '../../Sessions/modals/LoginSignupModal';

export const FinishSignupBanner = () => {
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const displayName = (user !== null && (user.displayName || user.firstName || user.email)) || 'Flow Club Guest'

  const linkStyle = css`
    color: white;
    text-decoration: underline;
    &:hover {
      color: #ffffffb0;
    }
  `
  return (
    <DescriptivelyNamedPurpleBanner>
      Welcome back {displayName}!&nbsp;
      <LinkStyledText
        onClick={() => setActiveModal(LoginSignupModal, { showBookingRelatedText: false })}
        customCss={linkStyle}
      >
        Set a password or login with Google to finish signing up
      </LinkStyledText>
    </DescriptivelyNamedPurpleBanner>
  )
}