/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, useEffect, useRef } from 'react';
import { UserContext } from '../UserProvider';
import { LoginSignup } from '../auth/LoginSignup';

export const SignupPane = () => {
  const { goToNextPane } = useContext(InterstitialContext)

  const { user } = useContext(UserContext)

  // skip pane is user is already logged in
  const skippingPane = useRef(false)
  useEffect(() => {
    if (user !== null && !skippingPane.current) {
      skippingPane.current = true
      goToNextPane()
    }
  }, [user])

  return (
    <>
      <div css={css`max-width: 360px;`}>
        <LoginSignup showLoginModeLink={false} startInLoginMode={false} showBookingRelatedText={false}/>
      </div>
    </>
  )
}