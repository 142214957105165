/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { ConnectCalendarButton } from '../../../CalendarBooking/ConnectCalendarButton';
import { UserContext } from '../../../UserProvider';
import { Text, TEXT_STYLES } from '../../flowComponents';

export const ConnectGoogleCalendar = () => {
  const { user } = useContext(UserContext)
  const userHasRefreshToken = user !== null && user.googleCalendarAuth !== undefined

  const connectedCalendar = userHasRefreshToken && user.googleCalendarAuth.calendarId
      
  return (
    <div css={css`display: flex; flex-direction: column; gap: 24px;`}>
      <Text style={TEXT_STYLES.MARKETING_H6}>Connect Google Calendar</Text>
      <Text>Connect your calendar to customize the session title right on your calendar for time blocking, and to see your busy times when you book or create.</Text>
      {userHasRefreshToken && <Text>Connected to <b>{connectedCalendar}</b></Text>}
      <ConnectCalendarButton />
    </div>
  )
}