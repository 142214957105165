/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getUserCanHostSessions } from '../../../functions/shared/helpers';
import { LightGrayText } from '../../components/flowComponents';
import { UserContext } from '../../UserProvider';

export const NoSessionsMessage = () => {
  const { user } = useContext(UserContext)
  const userCanHostSessions = getUserCanHostSessions(user)

  const ActionLink = userCanHostSessions ?
    // this being a literal link for creating a session is a little bit silly but it keeps this component simple, at least
    <Link to="/upcoming/create/">creating a session!</Link> :
    <a href="https://flowclub.typeform.com/to/QbcZ0jyB" target="_blank" rel="noreferrer">issuing a session request!</a>

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 96px;
      padding-bottom: 96px;
    `}>
      <LightGrayText customCss={css`
        font-family: 'Sato';
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.18px;

        margin-bottom: 16px;
      `}>
        No Sessions Available
      </LightGrayText>
      <LightGrayText customCss={css`
        line-height: 24px;
        letter-spacing: 0.5px;
      `}>
        We're sorry, no sessions match your criteria. Try broadening your criteria or {ActionLink}
      </LightGrayText>
    </div>
  )
}